import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropFilesComponent } from './dragdrop-files.component';
import { DndDirective } from 'src/app/core/directives/dnd.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    DragDropFilesComponent,
    DndDirective
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,

  ],
  exports: [
    DragDropFilesComponent
  ]
})
export class DragDropFilesModule { }
