import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopinRecruitersListComponent, PopinEntryRecruitersListComponent } from '../popin-recruitersList/popin-recruitersList.component';
import { RouterModule } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { LoadingModule } from '../../loading/loading.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopinInvitationModule } from '../popin-invitation/popin-invitation.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    //declarations: [PopinEntryRecruitersListComponent, PopinRecruitersListComponent],
    declarations: [PopinRecruitersListComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        // RouterModule.forChild([
        //     {
        //         path: '', component: PopinEntryRecruitersListComponent,
        //     },
        // ]),
        MatFormFieldModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatListModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatCheckboxModule,
        LoadingModule,
        MatTooltipModule,
        PopinInvitationModule,
        MatProgressSpinnerModule
    ],
    exports: [
        PopinRecruitersListComponent
    ]
})
export class PopinRecruitersListModule { }
