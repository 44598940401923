import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '@env';
import { AuthMSALService } from './auth-msal.service';



@Injectable({
  providedIn: 'root'
})
export class SourcingService {
  permissionHeader;
  userId;
  url = `${environment.path.api2}/sourcing`;

  constructor(private actRoute: ActivatedRoute, private apollo: Apollo, private http: HttpClient,
    private auth: AuthMSALService) {
    this.permissionHeader = {
      'x-jobology-pre-auth': 'true'
    }

    this.userId = this.auth.getClaims('sub');

  }

  public convertToText = (formData) => {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'multipart/form-data');

    return this.http.post(`${this.url}/file/convert/to/text`, formData, {
      headers: headers
    })
  }

  public getRoles = (context?) => {
    let url = this.url + '/role';

    if (context) {
      url = url + '?context=' + context;
    }
    return this.http.get(url)
  }

  public getAllMembers = () => {
    return this.http.get(this.url + '/utilisateur')
  }

  public origineProfil() {
    return this.http.get(`${this.url}/origineprofil`);
  }

  /** POSTES A POURVOIR */
  public getPostesAPourvoir = (page = 1, rows = 15, options = <any>{}) => {
    // let url = `${environment.path.api2}/cache/postesapourvoir/${guId}?`;
    //let url = `${environment.path.api2}/cache/postesapourvoir?`;
    let url = `${environment.path.api2}/sourcing/posteapourvoir/full?recruteurId=${this.userId}&`;

    if (page && rows) {
      url = url + 'page=' + page + '&rows=' + rows
    }

    if (options.colName) {
      url = url + '&sort=' + options.colName + '&sens=' + options.sort;
    }

    if (options.mc) {
      url = url + '&mc=' + options.mc;
    }

    if (options.localiteId) {
      url = url + '&localiteid=' + options.localiteId;
    }

    if (options.datede && options.datea) {
      url = url + `&datede=${options.datede}&datea=${options.datea}`;
    }

    if (options.statut) {
      url = url + `&statutid=${options.statut}`;
    }

    if (options.diffusion !== null) {
      url = url + `&diffusion=${options.diffusion}`;
    }

    if (options?.departementId) {
      url = url + `&departementId=${options.departementId}`;
    }

    if (options?.categorieEtapeFluxId) {
      url = url + `&categorieEtapeFluxId=${options.categorieEtapeFluxId}`;
    }

    return this.http.get<any>(url, /*{ headers: { 'x-jobology-pre-auth': 'postesAPourvoir' } }*/)
      .pipe(
        tap(() => this.refetchSubject.next({})),
        catchError(err => {
          return throwError(err)
        })
      );
  }
  refetchSubject = new Subject();

  public getPosteApourvoir = (postId) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${postId}`;

    return this.http.get<any>(url);
  }

  public createPosteAPourvoir = (datas, permission?) => {
    const headers = new HttpHeaders();
    let url = this.url + '/posteapourvoir';
    let infos;

    if (!permission) {
      infos = datas;
    } else {
      url = url + '?permission=createPosteAPourvoir';

      infos = {
        createPosteAPourvoir: true,
        departementId: datas.departementId
      }
    }


    return this.http.post(url, infos, { headers: permission ? this.permissionHeader : '' })
  }

  public updatePosteApourvoir = (datas, permission?) => {
    let url = `${this.url}/posteapourvoir/${datas.id}`;
    let input;

    if (!permission) {
      input = {
        libelleDuPoste: datas.libelleDuPoste,
        reference: datas.reference,
        detailPoste: datas.detailPoste,
        localite: datas.localite,
        contrat: datas.contrat,
        niveauEtude: datas.niveauEtude,
        niveauExperience: datas.niveauExperience,
        afficherSalaire: datas.afficherSalaire,
        disponibilite: datas.disponibilite,
        offreAnonyme: datas.offreAnonyme,
        presentationSociete: datas.presentationSociete,
        teletravail: datas.teletravail,
        modeleFluxId: datas.modeleFluxId,
        salaire: datas.salaire,
        departementId: datas.departementId,
        statutId: datas.statutId
      }
    } else {
      input = {
        updatePosteApourvoir: ''
      }
    }


    return this.http.patch(url, input, { headers: permission ? this.permissionHeader : '' });
  }

  /** SOURCES */
  public getSources(isJobology?, includeCount?) {
    let url = isJobology ? this.url + '/source/jobology' : this.url + '/source';
    url = includeCount ? url + '?includeCountProfil=true' : url;
    return this.http.get(url);
  }

  public getTypesSource = () => {
    return this.http.get(this.url + '/source/typesource');
  }

  public createSource = (datas, permission?) => {
    let input;

    if (!permission) {
      input = {
        libelle: datas.libelle,
        typeSourceId: datas.typeSourceId,
        recruteurId: datas.recruteurId
      }
    } else {
      input = {
        // Get the name of the request for permissionsList instead of parameters
        createSource: datas.recruteurId,
      }
    }

    return this.http.post(this.url + '/source', input, { headers: permission ? this.permissionHeader : '' });
  }

  public deleteSource = (id, permission?) => {
    let input;

    if (!permission) {
      input = {
        id: id
      }
    } else {
      input = {
        deleteSource: id
      }
    }

    let url = this.url + '/source/' + id

    if (permission) {
      url = url + '?permission=deleteSource';
    }

    return this.http.delete(url, { headers: permission ? this.permissionHeader : '' });
  }

  refreshSource: Subject<any> = new Subject();
  public updateSource = (datas, permission?) => {
    let input;

    if (!permission) {
      input = {
        libelle: datas.libelle,
        typeSourceId: datas.typeSourceId
      }
    } else {
      input = {
        updateSource: datas.id
      }
    }


    return this.http.patch(this.url + '/source/' + datas.id, input, { headers: permission ? this.permissionHeader : '' });
  }


  /** DIFFUSION */
  public createDiffusionCheck(datas) {
    let input = {
      posteapourvoirId: datas.posteapourvoirId,
      sourceId: datas.sourceId
    }
    return this.http.post(this.url + '/diffusion/check', input);
  }

  public createDiffusion(datas, permission?) {
    let input;

    if (!permission) {
      input = {
        posteapourvoirId: datas.posteapourvoirId,
        sourceId: datas.sourceId
      }
    } else {
      input = {
        createDiffusion: true
      }
    }

    return this.http.post<any>(this.url + '/diffusion', input, { headers: permission ? this.permissionHeader : '' });
  }


  public getDiffusion(posteAPourvoirId?, countCandidature?, countLecture?) {
    let url = this.url + '/diffusion';

    if (posteAPourvoirId) {
      url = url + '?posteapourvoirId=' + posteAPourvoirId;
    }

    if (countCandidature) {
      url = url + '&includeCountCandidature=true';
    }

    if (countLecture) {
      url = url + '&includeCountLecture=true';
    }
    return this.http.get(url);
  }

  public getDiffusionById(diffusionId) {
    return this.http.get(this.url + '/diffusion/' + diffusionId);
  }

  public updateDiffusionById(diffusionId, status) {
    let input = {
      statutId: status
    }
    // Statut 2 to unpublish
    return this.http.patch(this.url + '/diffusion/' + diffusionId, input);
  }


  /** DEPARTEMENTS */

  public typeDepartement() {
    return this.http.get(this.url + '/departement/typedepartement')
  }

  public getDepartements = (recruteurId?, context?) => {
    let url = `${this.url}/departement?`

    if (recruteurId) {
      url = url + `recruteurId=${recruteurId}`
    }

    if (context) {
      url = url + `&search=${context}`
    }

    return this.http.get(url)
  }

  public getAllDepartements = (recruteurId, rows?, page?) => {
    let url = `${this.url}/departement/full?`

    if (recruteurId) {
      url = url + `recruteurId=${recruteurId}`
    }

    if (rows) {
      url = url + `&rows=${rows}`;
    }

    if (page) {
      url = url + `&page=${page}`;
    }

    return this.http.get(url)
  }

  public getDepartementMembers(departementId, rows?, page?, departementRoleId?) {
    let url = `${this.url}/departement/${departementId}/membre?`

    if (rows) {
      url = url + `rows=${rows}`;
    }

    if (page) {
      url = url + `&page=${page}`;
    }

    if (departementRoleId) {
      // ID 5 = Responsables / ID 6 = Membres
      url = url + `&departementRoleId=${departementRoleId}`;
    }

    // if (isManager) {
    //   url = url + `&listeResponsables=true`;
    // }


    return this.http.get(url)
  }

  addDepartementMember = (datas, permission?) => {
    let url = `${this.url}/departement/${datas.departementId}/membre`;
    let memberInfos;

    if (!permission) {
      memberInfos = {
        recruteurId: datas.recruteurId,
        recruteurRoleId: datas.recruteurRoleId,
        departementRoleId: datas.departementRoleId
      }
    } else {
      memberInfos = {
        // Get the name of the request for permissionsList instead of parameters
        addDepartementMember: datas.recruteurId,
      }
    }


    return this.http.post(url, memberInfos, { headers: permission ? this.permissionHeader : '' });
  }

  deleteDepartementMember = (datas) => {
    let url = `${this.url}/departement/${datas.departementId}/membre/${datas.recruteurId}`;

    return this.http.delete(url);
  }

  updateDepartementMember = (datas) => {
    let url = `${this.url}/departement/${datas.departementId}/membre/${datas.recruteurId}`;


    return this.http.patch(url, datas);
  }

  updateDepartementMembers = (departementId, usersArray, permission?, isManager?) => {
    let url = `${this.url}/departement/${departementId}/membres`;

    if (permission) {

      usersArray = [{
        updateDepartementMembers: 'updateDepartementMembers',
        [departementId]: departementId
      }]

    }

    if (isManager) {
      url = url + '?listeResponsables=true'
    }

    return this.http.post(url, usersArray, { headers: permission ? this.permissionHeader : '' });
  }

  putDepartementMember = (datas, permission?) => {
    let url = `${this.url}/departement/${datas.departementId}/membre`;

    if (permission) {

      datas = {
        putDepartementMember: datas.recruteurId,
        [datas.departementId]: datas.departementId
      }

    }

    return this.http.put(url, datas, { headers: permission ? this.permissionHeader : '' });
  }

  public getDepartement = (departementId) => {
    return this.http.get(`${this.url}/departement/${departementId}`);
  }

  createDepartement(datas, permission?) {
    let url = `${this.url}/departement`;
    let departementInfos;

    if (!permission) {
      departementInfos = {
        libelle: datas.libelle,
        recruteurCreateurId: datas.recruteurCreateurId,
        typeDepartementId: datas.typeDepartementId,
        description: datas.description,
        partage: false
      }
    } else {
      departementInfos = {
        createDepartement: ''
      }
    }
    return this.http.post(url, departementInfos, { headers: permission ? this.permissionHeader : '', observe: 'response' });

  }

  refreshDepartement: Subject<any> = new Subject();
  updateDepartement = (datas, permission?) => {
    let url = `${this.url}/departement/${datas.departementId}`;
    let departementInfos;


    if (!permission) {
      departementInfos = {
        libelle: datas.libelle,
        typeDepartementId: datas.typeDepartementId,
        description: datas.description,
      }
    } else {
      departementInfos = {
        updateDepartement: ''
      }
    }


    return this.http.patch(url, departementInfos, { headers: permission ? this.permissionHeader : '' });
  }

  deleteDepartement = (datas, permission?) => {
    let url = `${this.url}/departement/${datas.departementId}`;

    if (permission) {
      url = url + '?permission=deleteDepartement'
    }

    return this.http.delete(url, datas);
  }

  public getPosteMembers = (postId, page = 1, rows = 15, options = <any>{}) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${postId}/membre?`;

    if (page && rows) {
      url = url + 'page=' + page + '&rows=' + rows
    }

    return this.http.get<any>(url);
  }

  public addPosteMember = (datas, permission?) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${datas.postId}/membre`;
    let infos;

    if (!permission) {
      infos = {
        recruteurId: datas.recruteurId,
        roleId: datas.roleId
      }
    } else {
      infos = {
        addPosteMember: ''
      }
    }

    return this.http.post<any>(url, infos, { headers: permission ? this.permissionHeader : '' });
  }

  public putPosteMember = (datas, permission?) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${datas.postId}/membre`;

    let infos = {
      recruteurId: datas.recruteurId,
      roleId: datas.roleId
    }

    return this.http.put<any>(url, infos, { headers: permission ? this.permissionHeader : '' });
  }

  public updatePosteMember = (datas, permission?) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${datas.postId}/membre/${datas.recruteurId}`;

    let infos = {
      roleId: datas.roleId
    }

    return this.http.patch<any>(url, infos, { headers: permission ? this.permissionHeader : '' });
  }

  public deletePosteMember = (datas, permission?) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${datas.postId}/membre/${datas.recruteurId}`;

    if (permission) {
      url = url + '?permission=deletePosteMember'
    }

    return this.http.delete(url);
  }

  public updatePosteMembers = (postId, usersArray, permission?) => {
    let url = `${environment.path.api2}/sourcing/posteapourvoir/${postId}/membres`;
    let infos;

    if (!permission) {
      infos = usersArray
    } else {

      infos = [{
        updatePosteMembers: true
      }]
    }


    return this.http.post<any>(url, infos, { headers: permission ? this.permissionHeader : '' });
  }

  /*** PROFIL / CANDIDAT ***/

  public checkCandidatureCVtheque(jobId, userInfos) {
    let url = `${environment.path.api2}/sourcing/profil/candidature/cvtheque-externe/check`;
    let input = {
      posteAPourvoirId: jobId,
      cvs: userInfos
    }

    return this.http.post<any>(url, input)
  }

  public addProfileToFlux(jobId, userInfos, permission?) {
    let url = `${environment.path.api2}/sourcing/profil/candidature/cvtheque-externe`;
    let input = {
      posteApourvoirId: jobId,
      userId: userInfos.userId,
      supportId: userInfos.supportId
    }

    return this.http.post<any>(url, input, { headers: permission ? this.permissionHeader : '' })
  }


}
