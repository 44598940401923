import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FooterService {
  // Detect current page to customize header
  public pagesList = {
    'post-a-job': {
      class: 'post-a-job-page',
      current: false
    },
    'resumes': {
      class: 'resumes-page',
      current: false
    },
    'checkout': {
      class: 'checkout-page',
      current: false
    },
    '404': {
      class: '404-page',
      current: false
    }
  };

  display: Subject<boolean> = new BehaviorSubject<boolean>(true);
  routerSub: Subscription = new Subscription;

  constructor(private router: Router) {

    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {

        switch (this.router.url) {
          // case '/':
          case '/qui-sommes-nous':
            this.display.next(true);
            break;
          default:
            this.display.next(false);
        }
      }
    });
  }

  updateDisplay(value) {
    this.display.next(value);
  }

}
