import { Component, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderService } from 'src/app/core/services/header.service';
import { HeaderComponent } from '../header/header.component';
import { SidenavAccountComponent } from './sidenav-account/sidenav-account.component';
import { Subscription, Subject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env';
import { BasketService } from 'src/app/core/services/basket.service';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';

@Component({
  selector: 'sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit {
  env = environment;
  private userDataSubscription: Subscription = new Subscription();
  public rootPath: string = '';
  public isRecruiters: boolean;


  @ViewChild('mainSidenav', { static: true }) public sidenav: MatSidenav;
  @ViewChild(SidenavAccountComponent, { static: true }) public sidenavAccount: MatSidenav;

  @Input('showSecondTheme') toto: boolean;
  @Output() switchTheme = new EventEmitter();

  username: any;
  shortUsername: string;
  email: string;
  routerSub: Subscription = new Subscription();

  // popinConf = new MatDialogConfig();

  roleIsRecruiter: boolean = false;

  constructor(public router: Router, public popin: MatDialog, public headerService: HeaderService, public authService: AuthMSALService) {
    /*this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.includes('espace-recruteurs')) {
          this.isRecruiters = true;
        } else {
          this.isRecruiters = false;
        }
      }
    });*/


  }


  ngOnInit() {
    if (!this.headerService.showAccountMenu) {
      this.headerService.setSidenav(this.sidenav);
    }

    if (this.env.GlobalVars.name == 'jobology') {
      this.isRecruiters = true;
    } else {
      this.isRecruiters = false;

    }
  }

  isOpen = false;
  openedChange(event) {
    this.isOpen = event;
    this.headerService.sidenavOpenedChange$.next(this.isOpen);
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
    // this.userDataSubscription.unsubscribe();
    this.headerService.close();
  }

  @Input('hideSidenav') hideSidenav: Subject<boolean>;
  ngAfterContentInit() {
    this.hideSidenav.subscribe(
      status => {
        if (status) {
          this.headerService.close();
        }
      }
    );
  }

  /* openLoginDialog() {
    this.popinConf = {
      id: 'popin',
      width: '80vw',
      maxWidth: '500px',
      maxHeight: 'auto',
      data: {

        email: this.email
      }
    };

    const popinRef = this.popin.open(PopinLoginComponent, this.popinConf);
  } */

  public toggleMyAccount() {
    this.sidenav.close();
    this.headerService.showAccountMenu = !this.headerService.showAccountMenu;
    this.sidenavAccount.open();
  }

  switchedTheme() {
    this.switchTheme.emit(this.isRecruiters = !this.isRecruiters);
    this.isRecruiters ? this.router.navigate(['/espace-recruteurs']) : this.router.navigate(['/']);
  }
}
