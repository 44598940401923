import { ViewEncapsulation, Component, OnInit, Input, ViewChild, Renderer2 } from '@angular/core';
import { HeaderService } from 'src/app/core/services/header.service';
import { environment } from '@env';
import { UserService } from 'src/app/core/services/user.service';
import { table } from 'console';
import { shareReplay, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PopinSubscriptionComponent } from '../../popin/popin-subscription/popin-subscription.component';
import * as clone from 'clone';

@Component({
  selector: 'nav-recruiters',
  templateUrl: './nav-recruiters.component.html',
  styleUrls: ['./nav-recruiters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavRecruitersComponent implements OnInit {
  env = environment;
  @Input() tenantActive
  @Input('subscription') subscription;

  constructor(public headerService: HeaderService, public userService: UserService,
    private dialog: MatDialog, private router: Router) { }

  ngOnInit() {
    this.getSubscription();
    this.getTenants();

  }

  ngAfterViewInit() {

  }

  tenantsList$;
  tenantsList;
  getTenants() {
    this.tenantsList$ = this.userService.memberTenantsList.subscribe(result => {
      if (result) {
        this.tenantsList = result;
      }
    })
  }

  displayExpiration;
  timeRemaining;
  getSubscription() {
    // If no active subscription
    // if (!this.subscription) {
    //   return false;
    // }
    this.userService.shareSubscriptionDatas$.pipe(take(1)).subscribe(result => {
      if (result) {
        this.subscription = clone(result);

        // Count days remaining
        // let dateFin = this.subscription.dateFinTrial ? Date.parse(this.subscription.dateFinTrial) : Date.parse(this.subscription.dateFin)
        let dateFin = Date.parse(this.subscription.dateFin)
        let dateToday = Date.now();

        // Convert ms to days
        this.timeRemaining = Math.floor((dateFin - dateToday) / (24 * 60 * 60 * 1000));

        if (this.timeRemaining <= 15 && !this.subscription.renouvellementAuto) {
          this.displayExpiration = true;
        }
      }

    })


  }

  setTenant(tenant) {
    //this.userService.setTenant(tenant);
    this.handleSubscription(tenant);
  }

  handleSubscription(tenant) {

    this.userService.getSubscription(tenant.id).pipe(take(1)).subscribe(result => {

      if (result) {
        this.subscription = result;

        // Count days remaining
        let dateFin = this.subscription.dateFinTrial ? Date.parse(this.subscription.dateFinTrial) : Date.parse(this.subscription.dateFin)
        let dateToday = Date.now();
        // Convert ms to days
        this.timeRemaining = Math.floor((dateFin - dateToday) / (24 * 60 * 60 * 1000));

        // Subscription ends
        if (!this.subscription.estActif) {
          this.userService.listenSubscriptionOn$.next(false);
          (<any>localStorage).setItem('subscriptionOn', 'false')
          this.openPopinSubscription(this.subscription, tenant)
        } else {
          this.userService.listenSubscriptionOn$.next(true);
          (<any>localStorage).setItem('subscriptionOn', 'true')
          this.userService.setTenant(tenant, true);
        }
      } else {

        this.userService.listenSubscriptionOn$.next(false);
        (<any>localStorage).setItem('subscriptionOn', 'false')
        this.openPopinSubscription(null, tenant)
      }


    })
  }

  openPopinSubscription(tenantSub, tenant) {
    let popin = this.dialog.open(PopinSubscriptionComponent, {
      width: '100%',
      maxWidth: '700px',
      disableClose: false,
      autoFocus: false,
      panelClass: 'popin-subscription-panel',
      data: {
        tenantSub: tenantSub,
        tenant: tenant,
        title: `Renouvelez votre abonnement pour ${tenant.name}`,
        content: `<p>Votre abonnement a expiré, ce qui signifie que vous n'avez plus accès à l'application.</p>
        <p>Pour continuer à profiter pleinement de notre application et de ses fonctionnalités avancées, nous vous invitons à
          renouveler votre abonnement dès maintenant.</p>
        <p>Pour consulter nos tarifs d'abonnement et choisir celui qui vous convient le mieux, veuillez cliquer
          sur le lien ci-dessous :
        </p>`,
        displayBack: false,
        displayClose: true,
        linkCTA: `${environment.path.blog.mainUrl}/mon-compte/subscriptions/?tenantid=${this.tenantActive.id}`
      }
    })
  }


  ngOnDestroy() {
    this.tenantsList$.unsubscribe();
  }
}
