import { NgModule, NO_ERRORS_SCHEMA, Renderer2 } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, NoPreloading, Router } from '@angular/router';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AtsComponent } from './pages/ats/ats.component';
import { CommonModule } from '@angular/common';
import { AtsNavMobileModule } from './pages/ats/nav-mobile/nav-mobile.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PopinWelcomeModule } from './pages/ats/popin-welcome/popin-welcome.module';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sourcing'
  },
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'postes'
  // },
  /* {
    path: '',
    loadChildren: () => import('./pages/ats/ats.module').then(m => m.AtsModule),

  }, */

  {
    component: AtsComponent,
    path: 'sourcing',
    loadChildren: () => import('./pages/ats/sourcing/sourcing.module').then(m => m.SourcingModule),
    canActivate: [AuthGuard],
  },
  // {
  //   component: AtsComponent,
  //   path: 'postes',
  //   loadChildren: () => import('./pages/ats/ats-jobs/ats-jobs.module').then(m => m.AtsJobsModule),
  //   data: { refresh: new Date().getTime() },
  //   canActivate: [AuthGuard],
  // },

  // {
  //   component: AtsComponent,
  //   path: 'vivier',
  //   loadChildren: () => import('./pages/ats/ats-pool/ats-pool.module').then(m => m.AtsPoolModule),
  //   canActivate: [AuthGuard],
  // },
  {
    component: AtsComponent,
    path: 'formation-recrutement',
    loadChildren: () => import('./pages/ats/formation-recrutement/formation-recrutement.module').then(m => m.FormationRecrutementModule),
    canActivate: [AuthGuard],
  },
  {
    component: AtsComponent,
    path: 'marque-employeur',
    loadChildren: () => import('./pages/ats/marque-employeur/marque-employeur.module').then(m => m.MarqueEmployeurModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'communication-rh/preview/:id',
    loadChildren: () => import('./pages/ats/marque-employeur/comm-rh/preview/preview.module').then(m => m.CommPreviewModule),
    //canActivate: [AuthGuard],
    //outlet: 'layout-outlet'
  },
  {
    path: 'site-carriere/:id',
    loadChildren: () => import('./pages/ats/marque-employeur/comm-rh/preview/preview.module').then(m => m.CommPreviewModule),
    //canActivate: [AuthGuard],
    //outlet: 'layout-outlet'
  },
  // {
  //   component: AtsComponent,
  //   path: 'gerer-mon-portrait-entreprise',
  //   loadChildren: () => import('./pages/company/company.module').then(m => m.CompanyModule),
  //   canActivate: [AuthGuard],
  // },
  {
    component: AtsComponent,
    path: 'parametres',
    loadChildren: () => import('./pages/ats/ats-settings/ats-settings.module').then(m => m.AtsSettingsModule),
    canActivate: [AuthGuard],
  },
  {
    component: AtsComponent,
    path: 'administration',
    loadChildren: () => import('./pages/ats/administration/administration.module').then(m => m.AtsAdministrationModule),
    canActivate: [AuthGuard],
  },
  {
    component: AtsComponent,
    path: 'utilisateur/mon-compte',
    loadChildren: () => import('src/app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AuthGuard],
  },

  /* {
    component: AtsComponent,
    path: 'mon-compte',
    loadChildren: () => import('src/app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    //canActivate: [AuthGuard],
  }, */
  {
    path: 'mon-compte',
    loadChildren: () => import('src/app/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
    // canActivate: [AuthGuard],
  },

  {
    path: 'conditions-generales-de-vente',
    loadChildren: () => import('./pages/cgv/cgv.module').then(m => m.CgvModule)
  },/*
  {
    path: 'conditions-dutilisation',
    loadChildren: () => import('./pages/cgu/cgu.module').then(m => m.CguModule)
  },
  {
    path: 'contactez-nous',
    loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
  },
  {
    path: 'cv',
    loadChildren: () => import('src/app/pages/cvtheque/cvtheque.module').then(m => m.CVthequeModule),
  },
  { path: 'inscription', loadChildren: () => import('./pages/inscription/inscription-page.module').then(m => m.InscriptionPageModule) },

  {
    path: 'politique-de-confidentialite',
    loadChildren: () => import('./pages/private-policy/private-policy.module').then(m => m.PrivatePolicyModule)
  },
  {
    path: 'recherche-cv',
    loadChildren: () => import('src/app/pages/offers/offers.module').then(m => m.OffersModule),
  },*/
  {
    path: 'candidat/:id/details',
    loadChildren: () => import('src/app/components/popin/popin-candidateDetails/popin-candidateDetails.module').then(m => m.PopinCandidateDetailsModule)
  },
  { path: '404', component: NotfoundComponent },
  { path: '**', redirectTo: '404' }
]


@NgModule({
  declarations: [AtsComponent],
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', initialNavigation: 'enabledBlocking', scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload', preloadingStrategy: NoPreloading }),
    CommonModule,
    AtsNavMobileModule,
    MatTooltipModule,
    FlexLayoutModule,
    PopinWelcomeModule,
    MatIconModule
  ],
  exports: [RouterModule, AtsComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppRoutingModule {


}
