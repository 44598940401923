import { errors } from './errors_fr';

export const GlobalVars = {
  brand: 'JOBOLOGY_FR',
  lang: 'FR',
  name: 'jobology',
  errors: errors,
  primaryColor: '#1e63ff',
  secondaryColor: '#FDC600'
};

