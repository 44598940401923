import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { environment } from '@env';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { take, map, startWith } from 'rxjs/operators';
import { JobType } from 'src/app/core/models/job.model';
import { AtsService } from 'src/app/core/services/ats.service';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { ErrorsService } from 'src/app/core/services/errors.service';
import { PopinService } from 'src/app/core/services/popin.service';
import { SourcingService } from 'src/app/core/services/sourcing.service';
import * as clone from 'clone';
import { MatRadioButton } from '@angular/material/radio';
import { ErrorStateMatcher } from '@angular/material/core';
import { PopinRecruitersListComponent } from '../popin-recruitersList/popin-recruitersList.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class PopinEntryJobDepositComponent {

  constructor(public dialog: MatDialog, private router: Router, private ats: AtsService) {
    this.openDialog();
  }

  openDialog(): void {
    let dialogRef = this.dialog.open(PopinJobDepositComponent, {
      id: 'popin-jobDeposit',
      panelClass: 'popin-jobDeposit-panel',
      width: '100%',
      maxWidth: '800px',
      data: {
        displayStepper: false
      },
      disableClose: true
    });


    dialogRef.afterClosed().subscribe(result => {
      let newUrl = this.router.url;

      newUrl = newUrl.split('/ajouter-un-poste')[0];

      // IF JOB CREATED, REDIRECT TO JOB CANDIDATES
      if (result) {
        this.router.navigateByUrl(`${newUrl}/${result}/candidats`);
      } else {
        this.router.navigateByUrl(newUrl);
      }
    });
  }

}

@Component({
  selector: 'popin-job-deposit',
  templateUrl: './popin-jobDeposit.component.html',
  styleUrls: ['./popin-jobDeposit.component.scss'],
  host: {
    class: 'popin-jobDeposit'
  },
  encapsulation: ViewEncapsulation.None
})
export class PopinJobDepositComponent implements OnInit {
  imgPath: string = environment.path.img;
  env = environment;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Description*',

    toolbarHiddenButtons: [
      ['heading', 'fontName', 'fontSize', 'justifyLeft',
        'subscript', 'superscript',
        'textColor',
        'backgroundColor',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'removeFormat',
        'toggleEditorMode',
        'underline',
        'strikeThrough',
        'insertVideo',
        'insertImage',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  }


  public jobInfos = {
    job: {
      detailPoste: '',
      libelleDuPoste: '',
      reference: '',
      recruteurCreateurId: '',
      modeleFluxId: null,
      departementId: null,
      localiteId: null,
      localite: ''
    }
  }

  public logoCompany: string;
  @ViewChild('stepper', { static: false }) stepper: MatStepper
  @ViewChild('autoCompleteComponent', { static: false }) autoCompleteComponent;
  displayStepper: boolean = false;
  displayOtherSteps: boolean = false;
  isLoading: boolean = false;
  isMobile;
  step1Completed: boolean = false;
  step2Completed: boolean = false;

  descriptionCTA = `Cliquez sur "Parcourir" pour charger votre fichier.<br>Extensions de fichier acceptées : .doc, .docx, .pdf, .txt, .rtf, .odt`;

  constructor(public popinService: PopinService, public render: Renderer2, @Inject(MAT_DIALOG_DATA) public data,
    private bk: BreakpointService, private ats: AtsService, private errorService: ErrorsService,
    public dialog: MatDialogRef<PopinJobDepositComponent>, private auth: AuthMSALService,
    private sourcingService: SourcingService, private router: Router, private matDialog: MatDialog, private snack: MatSnackBar) {
    if (this.data) {
      this.displayStepper = this.data.displayStepper;
    }

    this.bk.isMobile.pipe(take(1)).subscribe(state => {
      this.isMobile = state.matches;
    })
  }

  departementList;
  modelesFluxList;
  filteredDepartments;
  filteredModelesFluxList;
  ngOnInit() {
    // this.step1Form.controls.detailPoste.setValue('<font color="#3458A9"><font face="Helvetica, serif"><font size="3" style="font-size: 55pt">test</font></font></font>')
    // STEP 3 NO REFRESH HEIGHT SCROLL
    //this.popinService.handleHeightScroll(this.render);

    // this.ats.getNiveauxEtude().valueChanges.pipe(take(1)).subscribe(result => {
    //   this.niveauxEtude = result.data.niveauxEtude;
    // });

    // this.ats.getContracts().valueChanges.pipe(take(1)).subscribe(result => {
    //   this.contracts = result.data.contrats;
    // })

    // this.ats.getNiveauxExperience().valueChanges.pipe(take(1)).subscribe(result => {
    //   this.experience = result.data.niveauxExperience;
    // })

    this.jobInfos.job.recruteurCreateurId = this.auth.getClaims('sub');

    this.sourcingService.getDepartements(this.jobInfos.job.recruteurCreateurId, 'poste').pipe(take(1)).subscribe(result => {
      if (result) {
        const departements = clone(result);
        this.departementList = departements.data;

        this.filteredDepartments = this.step2Form.controls.departementForm.valueChanges.pipe(
          startWith(''),
          map(value => value ? this._filter(value, this.departementList) : this.departementList.slice())
        );
      }
    })

    this.ats.modelesFluxRecrutement(this.auth.getClaims('sub')).valueChanges.pipe(take(1)).subscribe(result => {
      if (result) {
        this.modelesFluxList = result.data.modelesFluxRecrutement;

        this.filteredModelesFluxList = this.step2Form.controls.modelesFluxForm.valueChanges.pipe(
          startWith(''),
          map(value => value ? this._filter(value, this.modelesFluxList) : this.modelesFluxList.slice())
        );
      }
    })

    this.errorService.errorMessage.pipe(take(1)).subscribe(err => {
      this.isLoading = false;
    });

    this.handleModelFluxChanges();

  }


  private _filter(value, listArray): string[] {
    const filterValue = value.toLowerCase();

    return listArray.filter(list => list.libelle.toLowerCase().indexOf(filterValue) === 0);
  }

  autoCompleteOptionSelected(event, autoCompleteInput) {
    if (autoCompleteInput === 'departement') {
      this.jobInfos.job.departementId = event.id;
    } else {
      this.jobInfos.job.modeleFluxId = event.id
    }

  }

  handleModelFluxChanges() {
    this.step2Form.controls.modelesFluxForm.valueChanges.subscribe(result => {

      if (result === '') {
        this.step2Form.controls.modelesFluxForm.setValue(null);
        this.jobInfos.job.modeleFluxId = null;

      }
    })
  }

  detailsLength = 0;
  // handleChars() {
  //    this.detailsLength = this.step1Form.controls.detailsPoste.value.length;
  // }

  maxCharLength = 20000;
  charLengthError = false;
  handleCharLength(event, name) {
    if (this.step1Form.controls.detailPoste.value !== '') {
      this.detailPosteEmpty = false;
    }

    this[name + 'Length'] = event.length
    if (event.length >= this.maxCharLength) {
      this.charLengthError = true;
      return false
    }

    this.charLengthError = false;
  }

  updateRadio(event) {
    // if switch with radio button, reset file uploaded
    this.file = ''
  }

  @ViewChild('fileRadio') fileRadio: MatRadioButton
  file;
  fileUploaded(event) {
    if (event) {
      this.file = event;
      this.fileRadio.checked = true;
    } else {
      this.file = '';
    }

  }

  @ViewChild('libelleInput') libelleInput: ElementRef;
  isSubmitting = false;
  convertFile() {
    if (!this.fileRadio.checked) {
      this.displayStepper = true;

      setTimeout(() => {
        this.libelleInput.nativeElement.focus();
      }, 600)
      return false;
    }

    this.isSubmitting = true;
    let formData: FormData = new FormData();
    formData.append('file', this.file), { type: 'multipart/form-data' };

    this.sourcingService.convertToText(formData).subscribe(result => {
      if (result) {
        let dataResult = clone(result);
        this.jobInfos.job.detailPoste = dataResult.texte;
        this.step1Form.controls.detailPoste.setValue(this.jobInfos.job.detailPoste);
        this.detailsLength = this.jobInfos.job.detailPoste.length;

        this.isSubmitting = false;
        this.displayStepper = true;

        setTimeout(() => {
          this.libelleInput.nativeElement.focus();
        }, 600)
      }
    }, err => {
      this.isSubmitting = false;
    })


  }
  changeSourcing(event) {
    if (event.value === 1) {
      this.displayOtherSteps = true;
    } else {
      this.displayOtherSteps = false;
    }
  }


  locationSelected(event) {
    //this.jobInfos.job.localiteId = event.id;
  }

  step1Form = new UntypedFormGroup({
    libelleDuPoste: new UntypedFormControl(this.jobInfos.job.libelleDuPoste, [Validators.required]),
    // reference: new UntypedFormControl(this.jobInfos.job.reference, [Validators.required]),
    detailPoste: new UntypedFormControl(this.jobInfos.job.detailPoste, [Validators.required]),
  });
  detailPosteEmpty = false;
  onSubmit(datas) {
    this.isLoading = true;

    if (this.autoCompleteComponent.locationsList.length === 0) {
      this.autoCompleteComponent.locationForm.setErrors({ incorrectFormat: true })
      this.isLoading = false;
      return;
    }

    // If list is loaded but user didn't select an option in the list
    if (this.autoCompleteComponent.input?.nativeElement?.value
      && this.autoCompleteComponent.locationsList.length > 0) {


      if (this.autoCompleteComponent.optionSelected === undefined) {
        //this.autoCompleteComponent.locationsList = [];
        this.autoCompleteComponent.locationForm.setErrors({ selectionList: true });

        this.isLoading = false;
        return false

        // this.autoCompleteComponent.locationForm.setValue(this.autoCompleteComponent.locationsList[this.autoCompleteComponent.locationsList.length - 1].data.locationAutocomplete[0].displayName)
        // this.jobInfos.job.localiteId = this.autoCompleteComponent.locationsList[0].data.locationAutocomplete[0].id;

      } else {

        this.jobInfos.job.localiteId = this.autoCompleteComponent.optionSelected.id;
        this.autoCompleteComponent.locationForm.setErrors(null)
      }


      // this.autoCompleteComponent.locationForm.setErrors(null);

    } else if (this.autoCompleteComponent.input.nativeElement.value === '') {
      this.autoCompleteComponent.locationForm.setErrors({ required: true });

      this.isLoading = false;

      return
    } else {

      this.autoCompleteComponent.locationForm.setErrors(null)
      this.isLoading = false;

    }

    // TO REMOVE
    // if (this.autoCompleteComponent.input.nativeElement.value === '') {
    //   this.autoCompleteComponent.locationForm.setErrors({ required: true });

    //   this.isLoading = false;

    //   return
    // } else {
    //   this.autoCompleteComponent.locationForm.setErrors(null)
    //   this.isLoading = false;

    // }

    if (this.charLengthError) {
      this.isLoading = false;
      return false
    }

    if (this.step1Form.controls.detailPoste.value === '') {
      this.detailPosteEmpty = true;
    } else {
      this.detailPosteEmpty = false;
    }

    this.jobInfos.job.libelleDuPoste = datas.controls.libelleDuPoste.value;
    this.jobInfos.job.detailPoste = datas.controls.detailPoste.value;
    this.jobInfos.job.localite = this.autoCompleteComponent.input.nativeElement.value;


    if (this.step1Form.valid) {
      this.step1Completed = true;

      setTimeout(() => {
        this.isLoading = false;
        this.stepper.next();



        if (this.data.departement?.id) {

          this.departementList.map(el => {
            if (el.id === this.data.departement.id) {
              this.step2Form.controls.departementForm.setValue(el.libelle)
              this.jobInfos.job.departementId = this.data.departement?.id
            }
          })


        }
      }, 1000)

    } else {
      this.step1Completed = false;
      this.isLoading = false;
      return;
    }
  }

  onSelectionChange(event) {
    // // If Step 1
    // if (event.selectedIndex === 0) {
    //   this.libelleInput.focus();
    // } else if (event.selectedIndex === 2) { // Step 3
    //   //   this.getCompanyDatas();
    //   // }
    // }
  }

  onChangeModeleFlux(event) {
    // Reset model flux if erase
    if (event.target.value == '') {
      this.step2Form.controls.modelesFluxForm.setValue(null);
      this.jobInfos.job.modeleFluxId = null;
    }
  }

  displayMembersAssignment = false;
  successCreate = false;
  step2Form = new UntypedFormGroup({
    departementForm: new UntypedFormControl('', [Validators.required]),
    modelesFluxForm: new UntypedFormControl(null),
    reference: new UntypedFormControl('', [Validators.required]),
  });
  postCreated;
  onSubmitStep2(datas) {
    this.isLoading = true;

    // this.jobInfos.job.departementId = datas.controls.departementForm.id;
    // this.jobInfos.job.modeleFluxId = datas.controls.modelesFluxForm.id
    this.jobInfos.job.reference = datas.controls.reference.value;


    if (!this.jobInfos.job.departementId) {
      this.step2Form.controls.departementForm.setErrors({ incorrect: true })
      this.isLoading = false;
      return false;
    }

    // if (this.step2Form.controls.modelesFluxForm.value !== '' && !this.jobInfos.job.modeleFluxId) {
    //   this.step2Form.controls.modelesFluxForm.setErrors({ incorrect: true })
    //   this.isLoading = false;
    //   return false;
    // }

    if (this.step2Form.valid) {
      this.step2Completed = true;

    } else {
      this.step2Completed = false;
      this.isLoading = false;
      return;
    }

    // this.displayStepper = false;
    // this.displayMembersAssignment = true;
    // this.stepper.next();
    // this.isLoading = false;

    // return


    this.sourcingService.createPosteAPourvoir(this.jobInfos.job).subscribe(result => {
      if (result) {
        this.isLoading = false;
        let datas = clone(result)
        this.postCreated = datas;

        this.displayStepper = false;
        this.displayMembersAssignment = true;
        this.stepper.next();

        //this.getCompanyMembers(datas)

        //this.successCreate = true;

        //this.confirmJobDeposit(datas.id)


      }

    }, err => {
      this.snack.open(`[${err.status}] Une erreur est survenue, veuillez réessayer.`, 'OK', { panelClass: 'error', duration: 0 })
      this.isLoading = false;
    })

  }


  confirmJobDeposit(jobId) {
    // this.ats.refreshPosteApourvoirs$.next(this.jobInfos.job);
    this.router.navigate(['/sourcing', 'postes', jobId, 'candidats']);

    setTimeout(() => {
      this.dialog.close();
      this.ats.displayCTAPosteAPourvoir$.next({ display: true });
    }, 500);
  }

  usersListInput = [];
  getCompanyMembers(checkCurrentUser?) {
    this.sourcingService.getAllMembers().pipe(take(1)).subscribe(res => {
      if (res) {
        const datas = clone(res);

        this.dialog.close();
        this.usersListInput.push(...datas);

        let dialog = this.matDialog.open(PopinRecruitersListComponent, {
          data: {
            usersList: this.usersListInput,
            title: `Créer l\'équipe du poste &laquo;${this.jobInfos.job.libelleDuPoste}&raquo;`,
            isSelectionList: true,
            departement: { id: this.jobInfos.job.departementId },
            posteAPourvoir: { id: this.postCreated.id },
            hasPermissions: true,
            context: 'poste-a-pourvoir',
            isEmpty: checkCurrentUser ? true : false,
            invitePosteAPourvoir: true
          },
          width: '100%',
          maxWidth: '700px',
          autoFocus: false
        })

        this.matDialog.afterAllClosed.pipe(take(1)).subscribe(res => {
          this.confirmJobDeposit(this.postCreated.id)
        })
      }
    }, err => {

    })
  }

  ngOnDestroy() {
    //this.dialog.close(this.jobInfos.job.id)
  }
}
