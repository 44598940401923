import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsOfferComponent } from './details-offer.component';
import { Route, RouterModule } from '@angular/router';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';

/*const DetailsOfferRoute: Route[] = [
  {
    path: ':id', component: DetailsOfferComponent
  }
]*/

@NgModule({
  declarations: [DetailsOfferComponent],
  imports: [
    CommonModule,
    // RouterModule.forChild(DetailsOfferRoute),
    LoadingModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatExpansionModule
  ],
  exports: [
    DetailsOfferComponent,
    RouterModule
  ]
})
export class DetailsOfferModule { }
