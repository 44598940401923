import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PopinService {

  constructor(@Inject(DOCUMENT) private document) {

  }

  handleHeightScroll(render: Renderer2) {

    //setTimeout(() => {
    const popinOverlay = this.document.querySelector('.cdk-overlay-pane');

    if (this.document.querySelector('.mat-dialog-content').offsetHeight > (<any>window).visualViewport.height) {

      render.setStyle(popinOverlay, 'overflow-y', 'auto');
      render.setStyle(popinOverlay, 'overflow-x', 'hidden');
      render.addClass(popinOverlay, 'scrollIsActive')
    } else {
      render.removeStyle(popinOverlay, 'overflow-y');
      render.removeStyle(popinOverlay, 'overflow-x');
      render.removeClass(popinOverlay, 'scrollIsActive')
    }
    //}, 50);
  }

}
