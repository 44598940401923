import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopinEntryJobDepositComponent, PopinJobDepositComponent } from './popin-jobDeposit.component';
import { DetailsOfferModule } from 'src/app/pages/offers/details-offer/details-offer.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropFilesModule } from '../../dragdrop-files/dragdrop-files.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoCompleteLocationModule } from '../../auto-complete-location/auto-complete-location.module';
import { MatSelectModule } from '@angular/material/select';
import { LoadingModule } from '../../loading/loading.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PopinRecruitersListModule } from '../popin-recruitersList/popin-recruitersList.module';


@NgModule({
    declarations: [PopinEntryJobDepositComponent, PopinJobDepositComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        DetailsOfferModule,
        FlexLayoutModule,
        DragDropFilesModule,
        AngularEditorModule,
        MatStepperModule,
        MatInputModule,
        MatCheckboxModule,
        MatRadioModule,
        MatButtonModule,
        MatIconModule,
        MatDialogModule,
        MatFormFieldModule,
        AutoCompleteLocationModule,
        MatSelectModule,
        LoadingModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatTooltipModule,
        PopinRecruitersListModule,
        RouterModule.forChild([
            {
                path: '', component: PopinEntryJobDepositComponent,
            },
        ]),
    ],
    exports: [
        // PopinJobDepositComponent
    ]
})
export class PopinJobDepositModule { }
