
import { Component, ViewEncapsulation, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { take } from 'rxjs';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';

@Component({
  selector: 'popin-subscription',
  templateUrl: './popin-subscription.component.html',
  styleUrls: ['./popin-subscription.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopinSubscriptionComponent {
  env = environment;
  isMobile;

  constructor(
    public dialogRef: MatDialogRef<PopinSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private actRoute: ActivatedRoute,
    private router: Router, private bk: BreakpointService) {
    this.bk.isMobile.pipe(take(1)).subscribe(state => {
      this.isMobile = state.matches;
    })

  }

  isAchat;
  params;
  ngOnInit() {
    this.params = this.actRoute.snapshot.queryParams;

    if (this.params?.achat == 'true' || (<any>sessionStorage).getItem('achat') == 'true') {
      this.isAchat = true;
    }

    this.manageRedirection();
  }

  manageRedirection() {

    // API ABONNEMENT VIDE
    if (!this.data.tenantSub) {

      // Redirect to payment
      if (this.params?.achat == 'true' || (<any>sessionStorage).getItem('achat') == 'true') {
        this.data.linkCTA = `${environment.path.blog.mainUrl}/paiement/?tenantid=${this.data?.tenant?.id}`
      } else {
        this.data.linkCTA = this.data.linkCTA ? this.data.linkCTA : `${environment.path.blog.mainUrl}/les-tarifs?tenantid=${this.data?.tenant?.id}`
      }
    }
    // ABONNEMENT EXISTANT
    else {
      // Already abo but disabled
      if (!this.data.tenantSub?.estActif) {
        this.data.linkCTA = `${environment.path.blog.mainUrl}/mon-compte/view-subscription/${this.data.tenantSub.abonnementId}?tenantid=${this.data.tenant.id}`
      }
    }
  }

}
