import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { AutoCompleteLocationComponent } from './auto-complete-location.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule, MAT_AUTOCOMPLETE_DEFAULT_OPTIONS } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';



@NgModule({
  declarations: [AutoCompleteLocationComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule
  ],
  exports: [
    AutoCompleteLocationComponent
  ],
  /*   providers: [
      {
        provide: MAT_SELECT_CONFIG,
        useValue: { overlayPanelClass: 'autocomplete-location-overlay__select' }
      },
      {
        provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
        useValue: { overlayPanelClass: 'autocomplete-location-overlay__city' }
      },
    ], */
})
export class AutoCompleteLocationModule { }
