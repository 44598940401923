import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissionsService } from '../../core/services/permissions.service';
//import { FeatureGuard } from './guards/feature.guard';
//import { CheckPermissionsDirective } from '../../core//directives/check-permissions.directive';
import { NgxPermissionsModule, NgxRolesService } from 'ngx-permissions';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkHandler } from 'apollo-angular/http';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from '@env';




@NgModule({
  imports: [
    CommonModule,
    NgxPermissionsModule,
    HttpClientModule

  ],
  declarations: [
    //CheckPermissionsDirective,
  ],
  exports: [
    //CheckPermissionsDirective,
    NgxPermissionsModule
  ],
  providers: [
    //FeatureGuard,
    PermissionsService,
    //NgxRolesService
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: httpLink => {
    //     return {
    //       link: httpLink.create({
    //         uri: environment.path.api,
    //         // If needed, you can set custom headers here
    //         headers: new HttpHeaders({
    //           Toto: `Teqfqsqfsdqsf`
    //         })
    //       }),
    //       cache: new InMemoryCache()
    //     };
    //   },
    //   deps: [HttpLink]
    // }

  ],
})
export class PermissionsModule {

  constructor() { }



}