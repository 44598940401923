import { Injectable, OnDestroy, Inject, NgZone } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies, changePasswordRequest, profileEditRequest, tokenRequest } from '../../auth.config.ts';
import { filter, takeUntil, take } from 'rxjs/operators';
import { HeaderService } from './header.service';
import { environment } from '@env';
import { MatSnackBar } from '@angular/material/snack-bar';
import clone from 'clone';


@Injectable({ providedIn: 'root' })
export class AuthMSALService {
  isIframe = false;
  loginDisplay = false;
  datas;
  userDatas: Subject<any> = new Subject;
  roles = {
    recruiter: false,
    coordinator: false,
    consultantJobology: false
  };

  newIdTokenClaims;
  private readonly _destroying$ = new Subject<void>();


  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private headerService: HeaderService, private snack: MatSnackBar,) {
    // this.isIframe = window !== window.parent && !window.opener;


    if (this.authService.instance.getActiveAccount() && this.authService.instance.getActiveAccount().idTokenClaims['roles']) {
      this.handleRoles(this.authService.instance.getActiveAccount().idTokenClaims['roles'])
    }
  }


  getJWT() {
    return (<any>localStorage).getItem('token');
  }

  setLoginDisplay(datas?) {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  broadcastInProgress(subject) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(subject)
      )
      .subscribe(() => {
        this.datas = this.authService.instance.getActiveAccount()?.idTokenClaims;

        /* 
                if (this.datas?.acr !== 'b2c_1a_signup_signin') {
                  return false
                } */

        this.setLoginDisplay(this.datas);

        if (this.datas?.name) {
          this.datas.shortname = this.headerService.getShortname(this.datas?.given_name, this.datas?.family_name);
        }

        this.userDatas.next({ ...this.datas, ...{ authRoles: this.roles } });
      });
  }

  broadcastMSAL(subject) {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(subject)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;

        // Set bearer in localStorage
        localStorage.setItem('token', payload.accessToken)

        /* 
                if (payload.authority.toLowerCase() === b2cPolicies.authorities.signUpSignIn.authority.toLowerCase() + '/') {
                  console.log('toto')
                  this.authService.instance.setActiveAccount(payload.account);
                } */
        this.authService.instance.setActiveAccount(payload.account);
        if (this.authService.instance.getActiveAccount()) {

          // If NORMAL USER
          if (this.authService.instance.getActiveAccount().idTokenClaims['roles']) {
            this.handleRoles(this.authService.instance.getActiveAccount().idTokenClaims['roles'])
          } else {
            // If NEW USER - Missing roles and sourceUrl properties
            this.newIdTokenClaims = { ...this.authService.instance.getActiveAccount().idTokenClaims, ...{ authRoles: this.roles } }
          }

        }
      });

  }

  handleRoles(userRoles) {
    /*  userRoles.indexOf('RECRUTEUR') !== -1 ? this.roles.recruiter = true : this.roles.recruiter = false;
     userRoles.indexOf('RECRUTEUR-COORDINATEUR') !== -1 ? this.roles = { recruiter: false, coordinator: true, consultantJobology: false } : this.roles.coordinator = false;
     userRoles.indexOf('CONSULTANT-JOBOLOGY') !== -1 ? this.roles = { recruiter: false, coordinator: false, consultantJobology: true } : this.roles.consultantJobology = false; */
    userRoles.indexOf('RECRUTEUR') !== -1 ? this.roles.recruiter = true : this.roles.recruiter = false;
    userRoles.indexOf('RECRUTEUR-COORDINATEUR') !== -1 ? this.roles.coordinator = true : this.roles.coordinator = false;
    userRoles.indexOf('CONSULTANT-JOBOLOGY') !== -1 ? this.roles.consultantJobology = true : this.roles.consultantJobology = false;

    this.newIdTokenClaims = { ...this.authService.instance.getActiveAccount().idTokenClaims, ...{ authRoles: this.roles } }

  }

  login() {
    let req = tokenRequest

    /*   if (parameters) {
        req = {
          ...{ extraQueryParameters: parameters },
          ...tokenRequest
        }
      }
  
      console.log(req)
      console.log(parameters) */

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {

        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {

        this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {

      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...req } as RedirectRequest).subscribe(result => {
        });
      } else {
        this.authService.loginRedirect(req)
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        //mainWindowRedirectUri: "https://www.jobtransport.com"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  changePassword() {
    this.authService.loginPopup(changePasswordRequest).subscribe(response => {
      if (response) {
        this.snack.open('Le mot de passe a bien été modifié', 'OK', { duration: 2000 })
      }
    });
  }

  profileEdit(parameters?, redirectUriParam?) {
    /*     let editProfileFlowRequest = {
          scopes: ["openid"],
          authority: b2cPolicies.authorities.signUpSignIn.authority,
        }; */
    let customRequest = profileEditRequest;
    if (parameters) {
      customRequest = {
        ...{ extraQueryParameters: parameters },
        ...profileEditRequest
      }
    }

    /*     if (redirectUriParam) {
          customRequest = {
            ...customRequest,
            ...{ redirectUri: `${environment.path.root}/mon-compte/callback?${redirectUriParam}` }
          }
        } */

    // console.log(customRequest)

    this.authService.loginRedirect(customRequest).subscribe(response => {

    });

  }

  checkAndSetActiveAccount() {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */

    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  dataSource: any = [];
  getClaims(claim?: any) {
    //this.handleRoles(this.authService.instance.getActiveAccount().idTokenClaims['roles'])
    this.newIdTokenClaims = this.authService.instance.getActiveAccount()?.idTokenClaims;


    if (claim) {
      //return { ...this.authService.instance.getActiveAccount().idTokenClaims, ...{ authRoles: this.roles } }[claim];
      return { ...this.newIdTokenClaims }[claim];
    }

    //return { ...this.authService.instance.getActiveAccount().idTokenClaims, ...{ authRoles: this.roles } };
    return { ...this.newIdTokenClaims };


  }

  /*   ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
    } */


}

