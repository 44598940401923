import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Injectable, } from '@angular/core';
import { BehaviorSubject, from, Subject, take } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as Recruiters from '../models/usersRecruiters.model';
import { environment } from '@env';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { headersRequest } from 'src/app/auth.config.ts';

@Injectable({ providedIn: 'root' })
export class UserService {
  permissionHeader;
  tenantHeader;

  constructor(private cookie: CookieService, private http: HttpClient,
    private apollo: Apollo, httpLink: HttpLink,
    private snack: MatSnackBar, private router: Router) {
    this.permissionHeader = {
      [headersRequest.permission]: 'true'
    }

  }

  public getUserJobboards(userId, permission?) {
    let url = `${environment.path.api2}/identification/recruteur/jobboards/${userId}`;

    if (permission) {
      url = url + '?permission=getUserJobboards'
    }
    return this.http.get(url, { headers: permission ? this.permissionHeader : '' })
  }

  public getSubscription(tenantId, permission?) {
    let url = `${environment.path.api2}/organisation/societe/${tenantId}/abonnement/actif`;

    if (permission) {
      url = url + '?permission=getSubscription'
    }
    return this.http.get(url, { headers: permission ? this.permissionHeader : '' })
  }

  shareSubscriptionDatas$: Subject<any> = new Subject();
  listenSubscriptionOn$: Subject<any> = new Subject();

  // Company
  public getCompany = (permission?) => {
    let headers = permission ? this.permissionHeader : '';

    return this.apollo.watchQuery<{ company }>({
      query: gql`query company {
        company {
          id,
          address,
          address2,
          city,
          country,
          family {
            id,
            name,
            order
          },
          fax,
          logoBase64,
          name,
          phone,
          postalCode,
          website,
          origin {
            id, 
            name
          }
        }
      }`,
      context: {
        headers: headers
      }
    });
  }

  getSociete = (tenantId, permission?) => {
    let url = `${environment.path.api2}/organisation/societe/${tenantId}`;

    if (permission) {
      url = url + '?permission=getSociete'
    }
    return this.http.get(url, { headers: permission ? this.permissionHeader : '' })
  }

  public getCompanyLogo = () => {
    return this.apollo.watchQuery<{ company }>({
      query: gql`query company {
        company {
          id
          logoBase64
        }
      }`
    });
  }

  // Recruiter UPDATE ADDRESS
  public updateSociete = (form) => {
    const input: Recruiters.CompanyUpdateInput = {
      groupId: form.groupId,
      name: form.name,
      address: form.address,
      address2: form.address2,
      postalCode: form.postalCode,
      city: form.city,
      country: form.country,
      phone: form.phone,
      fax: form.fax,
      website: form.website,
      logoBase64: form.logoBase64,
      familleEntrepriseId: form.companyFamilyId,
      secteurEntrepriseId: form.originId,
      societeParentId: form.societeParentId
    }

    return this.http.patch(`${environment.path.api2}/organisation/societe/${form.id}`, input);

  }

  //  Recruiter USER
  public getRecruteur = (userId) => {
    return this.http.get(`${environment.path.api2}/organisation/utilisateur/${userId}`);
  }

  //  Recruiter USER UPDATE
  public updateRecruteur = (form) => {
    const input = {
      prenom: form.prenom,
      nom: form.nom,
      email: form.email,
      telephone: form.telephone,
      mobile: form.mobile,
      avatarBase64: form.avatarBase64
    };

    return this.http.patch(`${environment.path.api2}/organisation/utilisateur/${form.id}`, input);
  }

  public recruteursBySociete = (page?, pageSize?, sort?, sens?, permission?, forRouteAccess?) => {
    let headers = permission ? this.permissionHeader : '';

    return this.apollo.watchQuery<{ recruteursBySociete }>({
      query: gql`query recruteursBySociete($pageSize: Int!, $page: Int!, $sort: String, $sens: String) {
        recruteursBySociete(pageSize: $pageSize, page: $page, sort: $sort, sens: $sens)  {
          count
          recruteurs {
            avatarBase64
            email
            id
            mobile
            nom
            prenom
            roles {
              id
              nom
            }
            status
            telephone
            invitation {
              inviteurRecruteur {
                nom
                prenom
              }
            }
          }
        }
      }`,
      variables: { page: page, pageSize: pageSize, sort: sort, sens: sens },
      context: {
        headers: headers
      }
    })
  }

  // Roles
  public getRoles = () => {
    return this.apollo.watchQuery<{ roles }>({
      query: gql`query roles {
        roles {
          id,
          nom
        }
      }`,
    });
  }

  public updateRecruteurRole = (datas, permission?) => {
    const input = {
      recruteurId: datas.recruteurId,
      roleId: datas.roleId
    }
    return this.apollo.mutate<{ updateRecruteurRole }>({
      mutation: gql`mutation updateRecruteurRole($input: RecruteurRoleUpdateInput!) {
        updateRecruteurRole(input: $input) 
      }`,
      variables: { input: input },
      context: { headers: permission ? this.permissionHeader : '' }
    });
  }

  // Accepter une invitation
  public acceptInvite = (invitationId) => {
    return this.http.post(`${environment.path.api2}/organisation/invitation/${invitationId}/accept`, null);
  }

  // Invitation Test Système 
  public canInviteRecruteur = (tenantId, input, permission?) => {

    return this.http.post(`${environment.path.api2}/organisation/societe/${tenantId}/invitation/check`, input);
  }

  // Invitation Membre -  Recruteur
  public inviteRecruteur = (tenantId, input, permission?) => {
    return this.http.post(`${environment.path.api2}/organisation/societe/${tenantId}/invitation`, input, { headers: permission ? this.permissionHeader : '' });
  }

  // Renvoyer une invitation
  public sendInviteRecruteur = (invitationId, input?, permission?) => {

    return this.http.post(`${environment.path.api2}/organisation/invitation/${invitationId}/resend`, input, { headers: permission ? this.permissionHeader : '' });
  }

  public enableRecruteur = (recruteurId, input?, permission?) => {
    let inputDatas = input ? input : { 'actif': true };

    return this.http.patch(`${environment.path.api2}/organisation/societe/${this.getTenantActive().id}/utilisateur/${recruteurId}`, inputDatas, { headers: permission ? this.permissionHeader : '' });
  }

  public disableRecruteur = (recruteurId, input?, tenantId?, permission?) => {
    let inputDatas = input ? input : { 'actif': false };
    let societeId = tenantId ? tenantId : this.getTenantActive().id;

    return this.http.patch(`${environment.path.api2}/organisation/societe/${societeId}/utilisateur/${recruteurId}`, inputDatas, { headers: permission ? this.permissionHeader : '' });
  }

  public deleteRecruteur = (recruteurId, tenantId?, permission?) => {
    let societeId = tenantId ? tenantId : this.getTenantActive().id;

    let url = `${environment.path.api2}/organisation/societe/${societeId}/utilisateur/${recruteurId}`;

    if (permission) {
      url = url + '&permission=disableRecruteur'
    }

    return this.http.delete(url, { headers: permission ? this.permissionHeader : '' });
  }

  public cancelInviteRecruteur = (invitationId, permission?) => {
    let url = `${environment.path.api2}/organisation/invitation/${invitationId}?`;

    if (permission) {
      url = url + '&permission=cancelInviteRecruteur'
    }

    return this.http.delete(url, { headers: permission ? this.permissionHeader : '' });

  }

  public showWarningRecruteur = (recruteurId, permission?) => {
    return this.apollo.watchQuery<{ showWarningRecruteur }>({
      query: gql`query showWarningRecruteur($recruteurId: Guid!) {
        showWarningRecruteur(recruteurId: $recruteurId) 
      }`,
      variables: { recruteurId: recruteurId },
      context: { headers: permission ? this.permissionHeader : '' }
    });
  }

  addUserToTenant(tenantId, userId, role) {
    let url = `${environment.path.api2}/organisation/societe/${tenantId}/utilisateur`;
    let input = {
      utilisateurId: userId,
      roleId: role
    };
    return this.http.post(url, input);
  }

  public getTenantsAutocompleteAuthorization$: Subject<any> = new Subject();
  public getTenantsAutocomplete = (q, rows = 10, permission?) => {
    let url = `${environment.path.api2}/organisation/societe/autocomplete?q=${q}`;
    // if (permission) {
    //   url = url + '&permission=tenantsAutocomplete'
    // }
    return this.http.get(url);
  }

  public getUserTenant = (userId, includeInvite?, includeReco?) => {
    // let headers = new HttpHeaders().set('Access-Control-Allow-Origin', '*');
    // return this.http.get(`${environment.path.api2}/tenant/tenant`, { 'headers': headers });
    // return this.http.get(`${environment.path.api2}/tenant/Tenant`);

    let url = `${environment.path.api2}/organisation/utilisateur/${userId}/societe?`;
    if (includeInvite) {
      url = url + 'includeInvite=true';
    }

    if (includeReco) {
      url = url + '&includereco=true';
    }

    return this.http.get(url);
  }

  // Liste des tenants d'un utilisateur
  public getUserTenantById = (tenantId) => {
    let url = `${environment.path.api2}/organisation/societe/${tenantId}/tenant`;
    return this.http.get(url);
  }

  // Créer un tenant
  public createUserTenant = (input) => {
    // return this.http.post(`${environment.path.api2}/tenant/Tenant`, {
    //   "Name": tenantName,
    //   "IsDefault": true
    // });

    return this.http.post(`${environment.path.api2}/organisation/societe`, input);
  }

  // Check le statut de création d'un tenant
  public verifyUserTenant = (tenantId) => {
    //return this.http.get(`${environment.path.api2}/tenant/Tenant/${tenantId}`);
    return this.http.get(`${environment.path.api2}/organisation/societe/${tenantId}/tenant`);
  }


  // Retourne les utilisateurs de la société avec l'id spécifié
  getUsersByTenant(tenantId, page = 1, pageSize = 15, sort?, sens?, permission?) {
    let url = `${environment.path.api2}/organisation/societe/${tenantId}/utilisateur?`;

    if (page) {
      url = url + `page=${page}`
    }

    if (pageSize) {
      url = url + `&pageSize=${pageSize}`
    }

    if (sort) {
      url = url + `&sort=${sort}`
    }

    if (sens) {
      url = url + `&sens=${sens}`
    }

    if (permission) {
      url = url + '&permission=membres'
    }

    return this.http.get(url, { headers: permission ? this.permissionHeader : '' });
  }

  tenantsList: Subject<any> = new BehaviorSubject<any>([]);
  memberTenantsList: Subject<any> = new BehaviorSubject<any>([]);
  tenantActive: Subject<any> = new BehaviorSubject<any>({});
  setTenant(tenant, reloadPage?, noReload?) {

    if (!tenant.id || tenant.id === undefined) {
      tenant.id = tenant.tenantId
    }

    (<any>localStorage).setItem('tenantActive', JSON.stringify(tenant))

    this.tenantActive.next(tenant);

    if (reloadPage) {
      this.router.navigate(['sourcing']).then(() => {
        (<any>window).location.reload();
      });
    } else if (noReload) {

    } else {
      this.router.navigate(['sourcing']);
    }

    // this.snack.open(`Connecté(e) à la société : ${tenant.name}`, 'OK', { duration: 2000 })

  }
  setMemberTenantsList(tenantsList) {
    (<any>localStorage).setItem('memberTenants', JSON.stringify(tenantsList))

    this.memberTenantsList.next(tenantsList);
  }

  getMemberTenantsList() {
    return JSON.parse((<any>localStorage).getItem('memberTenants'))
  }

  setTenantsList(tenantsList) {
    (<any>localStorage).setItem('tenants', JSON.stringify(tenantsList))

    this.tenantsList.next(tenantsList);
  }

  getTenantsList() {
    return JSON.parse((<any>localStorage).getItem('tenants'))
  }

  removeTenantsList() {
    (<any>localStorage).removeItem('tenants');
  }

  setTempTenant(tenant) {
    (<any>localStorage).setItem('tenantTemp', JSON.stringify(tenant))
  }

  getTempTenant() {
    return JSON.parse((<any>localStorage).getItem('tenantTemp'))
  }

  removeTempTenant() {
    (<any>localStorage).removeItem('tenantTemp')
  }

  getTenantActive() {
    return JSON.parse((<any>localStorage).getItem('tenantActive'))
  }

  removeTenantActive() {
    (<any>localStorage).removeItem('tenantActive');
  }

  tenants;
  checkActiveTenant() {
    if ((<any>localStorage).getItem('tenantActive') !== null) {
      this.tenantActive.next(JSON.parse((<any>localStorage).getItem('tenantActive')));
    }

    if ((<any>localStorage).getItem('memberTenants') !== null) {
      this.memberTenantsList.next(JSON.parse((<any>localStorage).getItem('memberTenants')));
    }
  }

  tenantCreation$: Subject<any> = new Subject();

  // Society origins informations
  public secteursEntreprise = (permission?) => {
    return this.http.get(`${environment.path.api2}/organisation/societe/secteur`);
  }

  public taillesEntreprise = (permission?) => {

    return this.http.get(`${environment.path.api2}/organisation/societe/taille`);
  }

  public companyFamilies = (onlyIntermediaire?) => {

    let url = `${environment.path.api2}/organisation/societe/famille?`;

    if (onlyIntermediaire) {
      url = url + 'onlyIntermediaire=true'
    }

    return this.http.get(url);
  }


}
