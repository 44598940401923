import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { DragDropFilesComponent } from 'src/app/components/dragdrop-files/dragdrop-files.component';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from '@env';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';

@Component({
  selector: 'popin-welcome',
  templateUrl: './popin-welcome.component.html',
  styleUrls: ['./popin-welcome.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopinWelcomeComponent implements OnInit {
  env = environment;
  descriptionCTA = `Glissez/déposez une photo ou cliquez sur <br>"Choisir un fichier" pour charger votre photo.`

  constructor(@Inject(MAT_DIALOG_DATA) public datas, private userService: UserService,
    private snack: MatSnackBar, private dialog: MatDialogRef<PopinWelcomeComponent>, private auth: AuthMSALService) { }

  ngOnInit(): void {
    (<any>localStorage).setItem('newUser', 'false');
  }

  file;
  isSubmitting;
  fileUploaded(event) {
    if (event) {
      this.file = event;
    } else {
      this.file = '';
    }
  }

  submit() {
    this.isSubmitting = true;

    if (this.file) {
      this.userService.updateRecruteur({ id: this.datas.sub, avatarBase64: this.file }).pipe(take(1)).subscribe(result => {
        // this.auth.userDatas.next(result.data.updateRecruteur)

        this.auth.userDatas.next({ ...result, newUser: false })
        this.isSubmitting = false
        this.snack.open('La photo a été ajoutée', 'OK', { verticalPosition: 'bottom', duration: 1500 });
        this.dialog.close();
      }, err => {
        this.snack.open('La photo n\'a pas pu être ajoutée', 'OK', { verticalPosition: 'bottom', duration: 1500 });
        this.isSubmitting = false;
      });
    } else {
      this.isSubmitting = false;
      this.dialog.close();
    }
  }

}
