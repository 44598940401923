import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { environment } from '@env';

/* @Component({
  selector: 'offers',
  templateUrl: '../../pages/offers/offers.component.html',
  // styleUrls: ['./popin.component.scss']
})
export class PopinComponent implements OnInit {
  city: string;
  name: string;

  constructor(public popin: MatDialog) { }

  ngOnInit() {
  }

  openDialog() {
    const popinRef = this.popin.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: { name: this.name, city: this.city }
    })
  }

} */

@Component({
  selector: 'popin',
  templateUrl: './popin.component.html',
})
export class PopinComponent {
  email: string;
  submitted: boolean = false;
  imgPath: string = environment.path.img;


  formEmailSubscription = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required])
  });

  constructor(
    public dialogRef: MatDialogRef<PopinComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.email = data.email;
  }

  onSubmit(): void {
    this.dialogRef.close({
      submitted: this.formEmailSubscription.controls['email'].value! ? true : false,
      email: this.formEmailSubscription.controls['email'].value
    });

  }

}
