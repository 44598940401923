import { Component, OnInit, Input, Output, SimpleChanges, Renderer2, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/core/services/header.service';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UserService } from 'src/app/core/services/user.service';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import * as clone from 'clone';

@Component({
  selector: 'nav-right',
  templateUrl: './nav-right.component.html',
  // styleUrls: ['./nav-right.component.scss']
})
export class NavRightComponent implements OnInit {
  @Input('isRecruiters') isRecruiters: boolean;
  @Input('isSticky') isSticky: any;
  @Input('isLogged') isLogged: boolean;
  @Input('avatarRecruteur') avatarRecruteur;
  @Input('userDatas') userDatas;

  public env = environment;
  public shortUsername: string;
  private userDataSubscription: Subscription = new Subscription();
  tenantName;

  constructor(public popin: MatDialog, private router: Router, public headerService: HeaderService,
    public authService: AuthMSALService, public userService: UserService, public render: Renderer2,
    public el: ElementRef) {
  }

  datas;
  ngOnInit() {
    this.getTenants();

    this.authService.userDatas.subscribe(result => {
      if (result) {
        this.userDatas = result;
        this.avatarRecruteur = result.avatarBase64;
      }
    })

    // this.authService.userDatas.pipe(take(1)).subscribe(result => {
    //   this.userDatas = result;

    //   console.log(this.userDatas)

    // if (this.userDatas.sub) {
    //   this.userService.getRecruteur(this.userDatas.sub).subscribe(recruteurResult => {
    //     if (recruteurResult) {
    //       // this.avatarRecruteur = recruteurResult.data.recruteur?.avatarBase64;

    //       let result = clone(recruteurResult);
    //       this.avatarRecruteur = result.avatarBase64;
    //     }

    //   })
    // }
    // })

    // this.userDatas = this.authService.getClaims();

    // if (this.userDatas.sub) {
    //   this.userService.getRecruteur(this.userDatas.sub).subscribe(recruteurResult => {
    //     if (recruteurResult) {
    //       //this.avatarRecruteur = recruteurResult.data.recruteur?.avatarBase64;

    //       let result = clone(recruteurResult);
    //       this.avatarRecruteur = result.avatarBase64;
    //     }

    //   })
    // }


  }

  ngAfterViewInit() {
    this.userService.checkActiveTenant();
  }


  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnDestroy() {
    // this.userDataSubscription.unsubscribe();
    this.tenantsList$.unsubscribe();
    this.tenantActive$.unsubscribe();

  }

  tenantsList;
  tenantActive;
  tenantsList$;
  tenantActive$;
  getTenants() {
    this.tenantActive = this.userService.getTenantActive();

    this.tenantsList$ = this.userService.memberTenantsList.subscribe(result => {
      if (result) {
        this.tenantsList = result;
      }
    })


    this.tenantActive$ = this.userService.tenantActive.subscribe(result => {
      if (Object.keys(result)?.length > 0) {
        this.tenantActive = result;
      }
    })

  }



  public login() {
    this.authService.login();
  }

  public logout() {
    /* setTimeout(() => {
      this.authService.logout();
    }, 200); */
    this.authService.logout();
  }

  public popinConf = new MatDialogConfig;
  async openJobDeposit() {

    const { PopinJobDepositComponent } = await import('src/app/components/popin/popin-jobDeposit/popin-jobDeposit.component');

    this.popinConf = {
      id: 'popin-jobDeposit',
      width: '90vw',
      maxWidth: '800px',
      panelClass: 'popin-jobDeposit-panel',
      data: {
        name: 'foo',
        email: 'bar',
      }
    };

    const popinRef = this.popin.open(PopinJobDepositComponent, this.popinConf);
  }
}
