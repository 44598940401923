// Service
import { Injectable, INJECTOR, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { BrowserStorage } from 'angular-auth-oidc-client';
import { ApiService } from './api.service';
import { environment } from '@env';
import { MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';

@Injectable()
export class RouterNavService {

  private previousUrl: string = undefined;
  private currentUrl: string = undefined;

  routerSub: Subscription = new Subscription;
  isRecruiters: Subject<boolean> = new Subject<boolean>();
  isRecruiters$ = this.isRecruiters.asObservable();

  constructor(private router: Router, private api: ApiService, private location: Location) {
    this.currentUrl = this.router.url;

    this.routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        //console.log(event)

        this.handleDepartementJobDetails(event);
        // this.handleUrl(this.currentUrl, event);
        // this.setPreviousUrlStorage();

        // // Handle Site Logs
        // if (environment.production) {
        //   this.api.siteLog().subscribe(res => {
        //     console.log(res);
        //   });
        // }


      }
    });
  }


  private handleDepartementJobDetails(event) {
    let url = event.url;
    if (!url.includes('postes/') && !url.includes('details')) {
      (<any>sessionStorage).removeItem('previousUrl')
    }
  }

  private setPreviousUrlStorage() {
    if (!this.currentUrl.includes('/callback') && !this.previousUrl.includes('/callback')
      && !this.previousUrl.includes('/silent-renew')) {
      // console.log('previous url routernav', this.previousUrl);

      (<any>sessionStorage).setItem('returnurl', this.previousUrl);
      /*if (this.previousUrl === '/') {
        (<any>sessionStorage).setItem('returnurl', (<any>window).location.origin);
      }*/
    }
  }

  private handleUrl(url: string, event: any) {
    const loginUrl = 'mon-compte/identification';
    const dashboardUrl = 'postes';


    this.previousUrl = url;
    this.currentUrl = event.url;

    if (url === '/' || url.includes(`/${loginUrl}`) || url.includes('/inscription')) {
      this.previousUrl = dashboardUrl;
    } else if (url.includes('/panier')) {
      // Delete params "step=xx"
      this.previousUrl = '/panier';
    }
  }
}