import { Component, OnInit, Inject, Renderer2, ElementRef, HostListener, PLATFORM_ID } from '@angular/core';
import { environment } from '@env';
import { Router } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'scrolltop',
  templateUrl: './scrolltop.component.html',
  styleUrls: ['./scrolltop.component.scss'],
  // encapsulation: ViewEncapsulation.None,
  host: {
    // class: 'scrolltop'
  }
})
export class ScrollTopComponent implements OnInit {
  env = environment;

  private prevScrollpos: number = 0;

  constructor(private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: any,) {

  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.prevScrollpos = (<any>window).pageYOffset;
    }
  }

  @HostListener('window:scroll', [])
  scrollDisplaying() {
    const currentScrollPos = (<any>window).pageYOffset;

    if (this.prevScrollpos > currentScrollPos) {
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'bottom', '-8px');
    } else {
      this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'bottom', '-85px');
    }

    this.prevScrollpos = currentScrollPos;

    // Hide btn on top
    setTimeout(() => {
      if ((<any>window).pageYOffset === 0) {
        this.renderer.setStyle(this.el.nativeElement.childNodes[0], 'bottom', '-85px');
      }
    }, 100);
  }

  scrollTop() {
    (<any>window).scrollTo(0, 0);
  }

}
