import { Component, ElementRef } from "@angular/core";

@Component({
  selector: 'skeleton-rect',
  host: {
    'class': 'shimmer'
  },
  templateUrl: './skeleton.component.html',
  /*styles: [`
    :host {
      display: block;
      width: var(--skeleton-rect-width);
      height: var(--skeleton-rect-height);
      background: rgba(0, 0, 0, .5) no-repeat;
      /*background: rgb(239, 241, 246) no-repeat;
    }
  `],*/
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent {
  width: string;
  height: string;
  className: string;
  infiniteLoading;

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    const host = this.host.nativeElement;

    if (this.className) {
      host.classList.add(this.className);
    }

    host.style.setProperty('--skeleton-rect-width', this.width ?? '100%');
    host.style.setProperty('--skeleton-rect-height', this.height ?? '20px');

    // IF ERROR SET TIMEOUT TO HIDE
    if (!this.infiniteLoading) {
      setTimeout(() => {
        host.style.setProperty('display', 'none');
      }, 6000)
    }
  }
}