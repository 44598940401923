import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PopinForbiddenComponent } from './popin-forbidden.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { IconsService } from 'src/app/core/services/icons.service';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [PopinForbiddenComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        FlexLayoutModule,
        RouterModule
    ],
    exports: [
        PopinForbiddenComponent
    ],
    providers: [IconsService]
})
export class PopinForbiddenModule { }
