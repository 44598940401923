import { Component, OnInit, ViewEncapsulation, HostListener, ElementRef, ViewChild, Input, Renderer2 } from '@angular/core';
import { HeaderService } from '../../core/services/header.service';
import { IconsService } from 'src/app/core/services/icons.service';
import { Subject, Subscription, take } from 'rxjs';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { environment } from '@env';
import { Router } from '@angular/router';

import { WindowRefService } from 'src/app/core/services/window.service';

import { SidenavAccountComponent } from '../sidenav/sidenav-account/sidenav-account.component';
import { MatSidenav } from '@angular/material/sidenav';
import { UserService } from 'src/app/core/services/user.service';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import * as clone from 'clone';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  /* animations: [
    trigger('slideDown', [
      state('hide', style({

      }))
    ])
  ] */
})
export class HeaderComponent implements OnInit {
  public env = environment;
  public isMobile: boolean = true;
  // public shortUsername: string;

  public isRecruiters: boolean;
  private routerSub: Subscription;
  isSticky = {
    mode: false,
    threshold: false
  };

  userDataSubscription: Subscription;
  userDatas;
  avatarRecruteur;


  @Input('subscription') subscription;

  private readonly _destroying$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver, private renderer: Renderer2,
    public window: WindowRefService, public router: Router,
    public breakpoints: BreakpointService, public headerService: HeaderService, private icons: IconsService,
    public authService: AuthMSALService, private userService: UserService) {

    // this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait])
    //   .subscribe((state) => {
    //     this.isMobile = state.matches;
    //   });

    this.breakpoints.isMobile.subscribe(state => {
      this.isMobile = state.matches;
    })

  }

  @ViewChild('header', { read: ElementRef, static: true }) public headerBlock: ElementRef;
  // Scrolling manager for HEADER
  @HostListener('window:scroll', ['$event'])
  onScroll(event): void {
    /*     let headerElem = this.headerBlock.nativeElement;
    
        this.displayHeader(headerElem.parentElement);
    
        // If banner is displayed
        if (this.bannerBlock !== undefined) {
          headerElem = this.bannerBlock.nativeElement;
        }
    
        if (this.window.nativeWindow.pageYOffset > 0) {
          this.renderer.addClass(headerElem, 'mat-elevation-z4')
          this.renderer.addClass(headerElem.parentElement, 'header--sticky');
          this.isSticky.mode = true;
    
          if (this.window.nativeWindow.pageYOffset > 1400) {
            this.renderer.addClass(headerElem.parentElement, 'header--sticky-threshold');
            this.isSticky.threshold = true;
          } else {
            this.renderer.removeClass(headerElem.parentElement, 'header--sticky-threshold');
            this.isSticky.threshold = false;
          }
        }
        else {
          this.renderer.removeClass(headerElem, 'mat-elevation-z4');
          this.renderer.removeClass(headerElem.parentElement, 'header--sticky');
          this.renderer.removeClass(headerElem.parentElement, 'header--sticky-threshold');
          this.isSticky.mode = false;
          this.isSticky.threshold = false;
        } */
  }


  private prevScrollpos: number = 0;
  displayHeader(headerElement) {
    if (!this.isMobile) {
      this.renderer.removeClass(headerElement, 'scroll-top');
      this.renderer.removeClass(headerElement, 'scroll-down');

      return;
    }

    const currentScrollPos = (<any>window).pageYOffset;

    if (this.prevScrollpos < currentScrollPos) {
      this.renderer.addClass(headerElement, 'scroll-down');
    } else {
      this.renderer.removeClass(headerElement, 'scroll-down');
    }
    this.prevScrollpos = currentScrollPos;

    // Hide btn on top
    setTimeout(() => {
      if ((<any>window).pageYOffset === 0) {
        this.renderer.removeClass(headerElement, 'scroll-down');
      }
    }, 10);
  }

  toggle() {
    //  let headerElem = this.headerBlock.nativeElement;

    this.headerService.toggle();

    if (this.headerService.toggleActive) {
      //this.renderer.removeClass(headerElem, 'mat-elevation-z4')
      this.renderer.addClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen')
    } else {
      //this.renderer.addClass(headerElem, 'mat-elevation-z4')
      this.renderer.removeClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen')
    }
  }


  @ViewChild(SidenavAccountComponent, { static: false }) public sidenavAccount: MatSidenav;
  public toggleMyAccount() {
    /*this.headerService.close();
    this.headerService.showAccountMenu = !this.headerService.showAccountMenu;
    this.sidenavAccount.open();*/
  }


  shortUsername: string;
  sidenavIsOpen;
  pwaIsActive = false;

  authService$;
  ngOnInit() {
    // DETECT PWA MODE
    this.pwaIsActive = (<any>window).matchMedia('(display-mode: standalone)').matches;

    this.getTenantActive();

    // Listen sidenav opening
    this.headerService.sidenavOpenedChange$.subscribe(result => {
      if (!result) {
        this.renderer.removeClass(this.headerBlock.nativeElement.parentElement, 'sidenavIsOpen');
      }
    })

    this.isRecruiters = true;


    this.authService.broadcastMSAL(this._destroying$);
    this.authService.broadcastInProgress(this._destroying$);

    this.authService$ = this.authService.userDatas.subscribe(result => {
      this.userDatas = result;
      this.avatarRecruteur = result.avatarBase64;

      if (result.sub) {
        this.userService.getRecruteur(result.sub).pipe(take(1)).subscribe(recruteurResult => {

          if (recruteurResult) {
            //  this.avatarRecruteur = result.avatarBase64;
            let result = clone(recruteurResult);
            this.userDatas = result;
            this.avatarRecruteur = result.avatarBase64;
          }
        })
      }

    })


    // If name is updated (my account)
    /*   this.userService.userDataSub.subscribe(userData => {
        this.shortUsername = this.headerService.getShortname(userData.given_name, userData.family_name);
      });
   */

  }

  tenantActive$;
  tenantActive;
  getTenantActive() {
    this.tenantActive = this.userService.getTenantActive();

    this.tenantActive$ = this.userService.tenantActive.subscribe(result => {
      if (Object.keys(result)?.length > 0) {
        this.tenantActive = result;
      }
    })
  }

  ngAfterViewInit() {

  }

  displayLogo = false;
  ngAfterContentChecked() {
    if (this.router.url.includes('/tenant') || this.router.url.includes('se-deconnecter')) {
      this.displayLogo = true;
    } else {
      this.displayLogo = false;
    }
  }

  ngOnDestroy() {
    // this.routerSub.unsubscribe();
    this._destroying$.next(undefined);
    this._destroying$.complete();
    // this.authService$.unsubscribe();
  }
}
