import { GlobalVars } from './global/jobology_fr';
import DicoKeys from '@dk/jobology_fr.json';


export const environment = {
  production: false,
  name: 'dev',
  path: {
    root: `https://app.dev.${GlobalVars.name}.fr`,
    img: `https://app.dev.${GlobalVars.name}.fr/assets/images/${GlobalVars.name}`,
    appRoot: 'app',
    auth: 'https://accounts.preprod.jobology.fr',
    api: 'https://api.dev.jobology.fr/graphql',
    api2: 'https://api.dev.jobology.fr',
    prod: `https://app.${GlobalVars.name}.fr`,
    blog: {
      url: 'https://www.cache.jobology.fr/wp-json/wp/v2/',
      tagId: 64,
      mainUrl: 'https://dev.jobology.fr'
    }
  },
  MSAL: {
    clientId: 'e2f2aee3-8bfb-4c44-8332-3aa12c67000e',
    scope: {
      path: 'jobologypreprod.onmicrosoft.com',
      url: `https://jobologypreprod.onmicrosoft.com/jobology-api/full`
    }
  },
  GlobalVars: GlobalVars,
  DK: DicoKeys,
  gtmID: 'FAKE_ID'
};


