
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app-jobology.module';
import { environment } from './environments/environment';
import 'hammerjs';
// import { MsalGuardConfiguration, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
// import { InteractionType, PublicClientApplication } from '@azure/msal-browser';


if (environment.production) {
  enableProdMode();
}

export function windowFactory() {
  return window;
}


export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', deps: [], useValue: environment.path.root },
  { provide: 'AUTH_URL', useValue: environment.path.auth },
  { provide: 'API_URL', useValue: 'https://localhost:44340' },
  // { provide: 'windowObject', useFactory: windowFactory, deps: [] }
  /* {
     provide: MSAL_INSTANCE, useValue: new PublicClientApplication({
       auth: msalConfig.auth,
       cache: msalConfig.cache,
       system: {
         loggerOptions: {
           loggerCallback(logLevel: LogLevel, message: string) {
             console.log(message);
           },
           logLevel: LogLevel.Info,
           piiLoggingEnabled: false
         }
       }
     })
   },
   {
     provide: MSAL_GUARD_CONFIG,
     useValue: {
       interactionType: InteractionType.Redirect
       /*  authRequest: json.guard.authRequest,
        loginFailedRoute: json.guard.loginFailedRoute 
     } as MsalGuardConfiguration
   }, */
  /*   { provide: MSAL_INTERCEPTOR_CONFIG, useValue: {
      interactionType: json.interceptor.interactionType,
      protectedResourceMap: new Map(json.interceptor.protectedResourceMap)
    } as MsalInterceptorConfiguration } */
];

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
});
