import { Component, OnInit, ViewEncapsulation, Renderer2, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { CookieService } from 'ngx-cookie-service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeaderService } from 'src/app/core/services/header.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EventMessage, EventType, InteractionStatus, AuthenticationResult } from '@azure/msal-browser';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'login'
  }
})
export class LoginComponent implements OnInit {
  formLogin = new UntypedFormGroup({
    login: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required])
  });

  app;
  loadingIsVisible: boolean = true;

  setUrlParam: string = this.actRoute.snapshot.queryParamMap.get('set');
  returnUrlParam: string = this.actRoute.snapshot.queryParamMap.get('returnurl');
  usernameParam: string = this.actRoute.snapshot.queryParamMap.get('username');

  constructor(private authService: AuthMSALService, private actRoute: ActivatedRoute,
    private render: Renderer2, private el: ElementRef, private msal: MsalService,
    private router: Router, private msalBroadcastService: MsalBroadcastService, private headerService: HeaderService) {

  }

  private readonly _destroying$ = new Subject<void>();
  ngOnInit() {
    this.app = this.el.nativeElement.parentElement.parentElement;
    this.render.addClass(this.app, 'login-page');

    /* if (this.returnUrlParam) {
      sessionStorage.setItem('returnurlparam', this.returnUrlParam);
    }

    setTimeout(() => {

      if (this.setUrlParam) {
        const param = `set=${this.setUrlParam}`;
        this.authService.login(param);

      } else if (this.usernameParam) {
        const param = `username=${this.usernameParam}`;
        this.authService.login(param);
      } else {
        this.authService.login(null);
      }
    }, 600); */


    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {

        if (this.msal.instance.getActiveAccount() == null) {
          this.authService.login();
        }

        this.authService.setLoginDisplay();
        this.authService.datas = this.msal.instance.getActiveAccount()?.idTokenClaims;
        if (this.authService.datas?.name) {
          this.authService.datas.shortname = this.headerService.getShortname(this.authService.datas?.given_name, this.authService.datas?.family_name);
        }

        this.router.navigate(['sourcing']);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msal.instance.setActiveAccount(payload.account);
      });

  }

  onSubmit(): void {

  }

  ngOnDestroy(): void {
    this.render.removeClass(this.app, 'login-page');
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
