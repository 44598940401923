import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from 'src/app/core/services/api.service';
import { JobType } from 'src/app/core/models/job.model';
import { ActivatedRoute } from '@angular/router';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { environment } from '@env'
import { Observable, Subscription } from 'rxjs';

const STATE_KEY_JOB = makeStateKey('jobInfos');

@Component({
  selector: 'job',
  templateUrl: './details-offer.component.html',
  styleUrls: ['./details-offer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DetailsOfferComponent implements OnInit {
  public imgPath: string = environment.path.img;
  public jobInfos$: Array<JobType>;
  loading = true;


  //jobInfos;
  @Input('simpleView') simpleView: boolean;
  @Input('jobInfos') jobInfos: any;
  @Input('jobId') jobId: number;

  // jobInfos: JobType;

  constructor(private routeId: ActivatedRoute, private api: ApiService, private state: TransferState) {
    this.jobId = this.jobId === undefined ? this.routeId.snapshot.params.id : this.jobId;
  }

  ngOnInit() {
    // const jobId = this.routeId.snapshot.params.id;
    // console.log(this.state.get(STATE_KEY_JOB, <JobType>{}), this.jobInfos);

    //this.jobInfos = this.jobInfos == undefined ? this.state.get<JobType>(STATE_KEY_JOB, <any>{}) : this.jobInfos;
    this.jobInfos = this.jobInfos == undefined ? this.state.get<any>(STATE_KEY_JOB, <any>{}) : this.jobInfos;


    if (Object.keys(this.jobInfos).length === 0) {
      this.api.getJobDetails(this.jobId).valueChanges.subscribe(result => {
        this.jobInfos = result.data;
        this.jobInfos$ = this.jobInfos;

        this.loading = false;

        //  this.state.set<JobType>(STATE_KEY_JOB, <any>result.data);
        this.state.set<any>(STATE_KEY_JOB, <any>result.data);


        return this.jobInfos$;
      });
    } else {
      this.loading = false;
    }
  }
}
