// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const errors = {
  msg: {
    PROMOTION_NOT_EXIST: 'La promotion n\'existe pas.',
    EMPTY_FIELD: 'Champs vide.',
    INVALID_EMAIL_ADDRESS: 'Email incorrect.',
    USERNAME_EXIST: 'Nom d\'utilisateur déjà existant.',
    USER_NAME_ALREADY_USED: 'Nom d\'utilisateur déjà utilisé.',
    INVALID_VALUE: 'Valeur incorrecte.',
    COMPANY_EXIST: 'Le nom d\'entreprise existe déjà.',
    INVALID_OPERATION: 'Opération invalide.',
    NULL_REFERENCE: 'Erreur technique.',
    INVALID_PASSWORD_LENGHT: 'Mot de passe trop court. 6 caractères minimum.',
  },
  request: {
    403: 'Erreur 403 : Accès refusé : Vous n\'avez pas les autorisations nécessaires pour accéder au contenu.'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
