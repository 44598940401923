
import { Component, ViewEncapsulation, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env';
import { take } from 'rxjs';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';

@Component({
  selector: 'popin-forbidden',
  templateUrl: './popin-forbidden.component.html',
  styleUrls: ['./popin-forbidden.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PopinForbiddenComponent {
  env = environment;
  isMobile;

  constructor(
    public dialogRef: MatDialogRef<PopinForbiddenComponent>,
    @Inject(MAT_DIALOG_DATA) public data, private actRoute: ActivatedRoute,
    private router: Router, private bk: BreakpointService) {
    this.bk.isMobile.pipe(take(1)).subscribe(state => {
      this.isMobile = state.matches;
    })

  }

  ngOnInit() {

  }

}
