import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopinWelcomeComponent } from './popin-welcome.component';
import { DragDropFilesModule } from 'src/app/components/dragdrop-files/dragdrop-files.module';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserService } from 'src/app/core/services/user.service';



@NgModule({
  declarations: [
    PopinWelcomeComponent
  ],
  imports: [
    CommonModule,
    DragDropFilesModule,
    MatButtonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [
    PopinWelcomeComponent
  ],
  /*  providers: [
     UserService
   ] */
})
export class PopinWelcomeModule { }
