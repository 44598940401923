import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { delay, take } from 'rxjs/operators';
import { UserService } from './user.service';
import { BreakpointService } from './breakpoints.service';

@Injectable({
  providedIn: 'root'
})
export class AtsNavService {
  mainLinks;
  public links: Array<object> = [
    //{ label: 'Sourcing', route: ['/postes'], icon: 'work', disabled: false, routerLink: true },
    { label: 'Sourcing', route: ['/sourcing'], icon: 'work', disabled: false, routerLink: true, className: 'sourcing' },
    // { label: 'Candidatures <br> spontanées', route: ['/candidatures-spontanees'], icon: 'email', disabled: true, routerLink: true },
    // { label: 'Vivier', route: ['/vivier', 'profils'], icon: 'groups', disabled: false, routerLink: true },
    // { label: 'Marque <br> employeur', route: ['/gerer-mon-portrait-entreprise'], icon: 'web', disabled: true, routerLink: true },
    { label: 'Marque <br> employeur', route: ['/marque-employeur'], icon: 'web', disabled: false, routerLink: true, className: 'marque-employeur' },
    { label: 'Formation<br>au recrutement', route: ['/formation-recrutement'], icon: 'devices', disabled: false, routerLink: true, className: 'formation' },
    { label: 'Paramètres', route: ['/parametres'], icon: 'settings', disabled: false, routerLink: true },
    // { label: 'Administration', route: ['/administration'], icon: 'domain', disabled: true, routerLink: true },
    { label: 'Mon compte', route: [], icon: 'lock', disabled: false, routerLink: false }
  ];

  public links$: Subject<Array<any>> = new BehaviorSubject<Array<any>>(this.links);

  displayAdmin = false;

  isMobile;
  isMobile$;
  constructor(private userService: UserService, private bk: BreakpointService) {
    this.mainLinks = this.links;

    // this.links$.pipe(take(1)).subscribe(result => {

    //   if (result) {
    //     let datas = result;

    //     // Handle Administration Menu display
    //     if (datas[0].label === 'Sourcing') {

    //       this.userService.getTenantsAutocompleteAuthorization$.subscribe(result => {
    //         if (result?.displayAdmin) {

    //           this.displayAdmin = true;
    //           this.links = [
    //             { label: 'Sourcing', route: ['/sourcing'], icon: 'work', disabled: false, routerLink: true, className: 'sourcing' },
    //             { label: 'Marque <br> employeur', route: ['/marque-employeur'], icon: 'web', disabled: false, routerLink: true, className: 'marque-employeur' },
    //             { label: 'Formation<br>au recrutement', route: ['/formation-recrutement'], icon: 'devices', disabled: false, routerLink: true, className: 'formation' },
    //             { label: 'Paramètres', route: ['/parametres'], icon: 'settings', disabled: false, routerLink: true },
    //             { label: 'Administration', route: ['/administration'], icon: 'domain', disabled: false, routerLink: true },
    //             { label: 'Mon compte', route: [], icon: 'lock', disabled: false, routerLink: false }
    //           ];

    //           this.mainLinks = this.links;

    //           console.log('UPDATE ADMIN', datas)

    //           // this.links$.next(this.mainLinks);

    //         }
    //       }, err => {
    //         this.displayAdmin = false;
    //       })

    //     }
    //   }
    // })
  }

  public updateNav(newElements: Array<any>, currentPage = {}, isMobile?) {
    let iconBack;
    let className = null;
    let route = ['sourcing'];

    if (currentPage.hasOwnProperty('name')) {
      iconBack = Object(currentPage).valueOf()['name'] === 'Sourcing' ? 'sourcing_back' : ('Marque-employeur' ? 'me_back' : 'devices');
    }

    if (currentPage.hasOwnProperty('className')) {
      className = Object(currentPage).valueOf()['className'];
    }


    // if (currentPage.hasOwnProperty('route')) {
    //   route.push(Object(currentPage).valueOf()['route']);
    // }

    // console.log('NEW ELEMENTS', newElements)
    // console.log('MAIN LINKS', this.mainLinks)
    if (Object.keys(currentPage).length > 0) {
      newElements.unshift(
        { label: 'Menu', route: route, icon: iconBack, ...currentPage, savedNav: [newElements], className: className !== null ? className : '' }
      );
    } // else {
    //   for (let i = newElements.length - 1; i >= 0; i--) {
    //     if (newElements[i].label === 'Menu') {
    //       newElements.splice(i, 1)
    //     }
    //   }
    // }

    // if (expand) {
    //   newElements.unshift(
    //     { expand: true }
    //   );
    // }

    this.links$.pipe(delay(0));
    this.links$.next(newElements);
  }

  public resetNav(item) {
    // if (item) {
    //   this.links.push({ name: item.name, savedNav: item.savedNav });
    // }

    // console.log('RESET', this.links)

    // this.links$.next(this.links);
    // this.links$.pipe(delay(0));
  }


  public restoreNav(name) {
    this.links.map(element => {
      if (element['name'] !== undefined && element['name'] === name) {
        this.updateNav(element['savedNav'][0]);
      }
    })
  }
}
