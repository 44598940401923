import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AtsNavService } from 'src/app/core/services/ats-nav.service';
import { HeaderService } from 'src/app/core/services/header.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'ats-nav',
  templateUrl: './nav-mobile.component.html',
  styleUrls: ['./nav-mobile.component.scss'],
  host: {
    class: 'ats-nav'
  },
  encapsulation: ViewEncapsulation.None,
})
export class AtsNavMobileComponent implements OnInit {
  navSub$: Subscription;
  navLinks: Array<any>;
  poolNavRoute$;
  linkActive
  expand: boolean = false;
  adminNavLinks = [
    { label: 'Sourcing', route: ['/sourcing'], icon: 'work', disabled: false, routerLink: true, className: 'sourcing' },
    { label: 'Marque <br> employeur', route: ['/marque-employeur'], icon: 'web', disabled: false, routerLink: true, className: 'marque-employeur' },
    { label: 'Formation<br>au recrutement', route: ['/formation-recrutement'], icon: 'devices', disabled: false, routerLink: true, className: 'formation' },
    { label: 'Paramètres', route: ['/parametres'], icon: 'settings', disabled: false, routerLink: true },
    { label: 'Administration', route: ['/administration'], icon: 'domain', disabled: false, routerLink: true },
    { label: 'Mon compte', route: [], icon: 'lock', disabled: false, routerLink: false }
  ];

  constructor(private navService: AtsNavService, public headerService: HeaderService,
    private router: Router, private userService: UserService) {

  }

  ngOnInit() {

  }

  displayAdmin = false;
  apiDisplayAdmin = false;
  ngAfterViewInit() {
    this.userService.getTenantsAutocompleteAuthorization$.subscribe(result => {

      if (result?.displayAdmin) {
        this.displayAdmin = true;

        this.handleAdminNav();

      }
    }, err => {
      this.displayAdmin = false;
    })


    this.navSub$ = this.navService.links$.pipe(delay(0)).subscribe(result => {
      if (result) {
        this.navLinks = result;
        this.expand = this.navLinks[0].expand;

        this.handleAdminNav();


        // this.navLinks.map(el => {
        //   if (el.label === 'Administration') {
        //     this.displayAdmin = true;

        //     return
        //   } else {
        //     this.displayAdmin = false;
        //   }
        // })

        // this.userService.getTenantsAutocompleteAuthorization$.subscribe(result => {
        //   console.log(result)
        //   console.log('NAV', this.navLinks)
        //   if (result?.displayAdmin) {
        //     if (this.navLinks[0].label == 'Sourcing') {
        //       this.displayAdmin = true;
        //       this.navLinks = this.adminNavLinks;
        //     } else {
        //       this.displayAdmin = false;
        //     }
        //   }
        // }, err => {
        //   this.displayAdmin = false;
        // })
      }
    });

  }

  handleAdminNav() {
    if (!this.navLinks) {
      return
    }

    if (this.navLinks[0].label == 'Sourcing' && localStorage.getItem('displayAdminNav')) {
      this.displayAdmin = true;
      this.navLinks = this.adminNavLinks;
    } else {
      this.displayAdmin = false;
    }
  }

  ngOnDestroy() {
    this.navSub$.unsubscribe();
  }

  ngOnChanges(changes) {

  }

  handleNav(item) {
    this.headerService.closeMyAccount()

    // if (item.route.length === 0) {
    //   this.navService.resetNav(item);
    // } else {
    //   this.navService.restoreNav(item.label);
    // }

    // if (item.name === 'Sourcing') {
    //   this.router.navigate(['sourcing']);
    // }

    // if (item.name === 'Marque-employeur') {
    //   this.router.navigate(['marque-employeur']);
    // }

    switch (item.name) {
      case 'Sourcing': this.router.navigate(['sourcing']);
        break;
      case 'Marque-employeur': this.router.navigate(['marque-employeur']);
        break;
      default: this.navService.resetNav(item);
    }

  }

}
