import { Apollo, gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { DOCUMENT } from '@angular/common';
import * as Products from 'src/app/core/models/products.model';


@Injectable({ providedIn: 'root' })
export class ApiService {

  constructor(@Inject(DOCUMENT) private document: Document, private http: HttpClient, private apollo: Apollo, httpLink: HttpLink) {
  }



  public siteLog(): Observable<any> {
    let postData = new FormData();
    postData.append('portalid', '-1');
    postData.append('referrer', this.document.referrer);
    postData.append('url', (<any>window).location.href);
    postData.append('tabid', '-1');

    return this.http.post<any>('/DesktopModules/CollectSiteLog.aspx', postData);
  }

  public getJobDetails = (id: number) => {
    return this.apollo.watchQuery({
      query: gql`query job($id: Int!){
        job(id: $id) {
          id,
          title,
          detail,
          availability,
          salary,
          updatedDate,
          reference,
          charter,
          companyDescription,
          createdDate,
          dateView,
          isAnonyme,
          isChartered,
          matchingDate,
          partenaireId,
          refreshDate,
          responseLink,
          responseType,
          salaryView,
          showSalary,
          skills,
          sourceId,
          statusId
        }
      }`, variables: { id: id }
    });
  }

  // Products Details for recruiters
  public getProduct = (id, quantity: number) => {
    return this.apollo.watchQuery<{ product: Products.Product }>({
      query: gql`query product($id: Guid!, $quantity: Int!){
        product(id: $id, quantity: $quantity) {
          id,
          description,
          discountDetails {
            id,
            maxQuantity,
            minQuantity,
            price
          },
          name,
          price,
          priceBase
        }
      }`, variables: { id: id, quantity: quantity }
    });
  }

  // Products for recruiters
  public getProducts = (id) => {
    return this.apollo.watchQuery<Products.ProductsByRange>({
      query: gql`query productsByRange($rangeId: Int!){
        productsByRange(rangeId: $rangeId) {
          id,
          description,
          discountDetails {
            id,
            maxQuantity,
            minQuantity,
            price
          },
          isOption,
          labelROI,
          labelTIPS,
          name,
          price,
          price_base,
          quantityPossible,
          validityDays
        }
      }`, variables: { rangeId: id }
    });
  }

  // Products By Page for recruiters
  public getProductsByPage = (pageName: string) => {
    return this.apollo.watchQuery<{ productsByPage: Products.Product }>({
      query: gql`query productsByPage($pageName: String!){
          productsByPage(pageName: $pageName) {
            id,
            description,
            htmlDescription,
            labelROI,
            labelTIPS,
            maxPossibleQuantity,
            name,
            priceDetail {
              id,
              price,
              discountDetails {
                discountAmount,
                id,
                maxQuantity,
                minQuantity,
                price
              }
            }
          }
        }`, variables: { pageName: pageName }
    });
  }

  // Blog
  public getBlogPostsList(perPage = 9, page = 1, offset = null): Observable<any> {
    let fields = `&_fields[]=author&_fields[]=tags&_fields[]=featured_media&_fields[]=yoast_meta&_fields[]=categories&_fields[]=title&_fields[]=excerpt&_fields[]=id&_fields[]=date&_fields[]=slug&_fields[]=yoast_head&_fields[]=head_tags&per_page=${perPage}&page=${page}&tags=${environment.path.blog.tagId}`;

    if (offset !== null) {
      fields = `&_fields[]=tags&_fields[]=featured_media&_fields[]=yoast_meta&_fields[]=categories&_fields[]=title&_fields[]=excerpt&_fields[]=id&_fields[]=date&_fields[]=slug&_fields[]=yoast_head&per_page=${perPage}&offset=${offset}&tags=${environment.path.blog.tagId}`;
    }

    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/posts?${fields}`, { observe: 'response' });
  }

  public getBlogPost(slugName): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/posts?slug=${slugName}&tags=${environment.path.blog.tagId}`);
  }

  public getBlogComments(postId): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/comments?post=${postId}`);
  }

  public getBlogImg(postId): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/media/${postId}`);
  }

  public getBlogCategories(): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/categories?per_page=100`);
  }

  public getBlogCategoriesByName(categoryName): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/categories?slug=${categoryName}`);
  }

  public getBlogPostsListByCat(categoryId, perPage = 9, page = 1): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/posts?categories=${categoryId}&per_page=${perPage}&page=${page}&tags=${environment.path.blog.tagId}`, { observe: 'response' });
  }

  public getBlogTags(categoryId): Observable<any> {
    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/tags?categories=${categoryId}`);
  }

  public getBlogAuthors(): Observable<any> {
    const timestamp = Date.now();

    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/users`);
  }

  public getCGU(): Observable<any> {
    return this.http.get('https://www.cache.jobology.fr/wp-json/wp/v2/pages/?slug=conditions-dutilisation');
  }

  public getCGV(): Observable<any> {
    return this.http.get('https://www.cache.jobology.fr/wp-json/wp/v2/pages/?slug=conditions-generales-de-vente');
  }

  public getPrivacyPolicy(): Observable<any> {
    return this.http.get('https://www.cache.jobology.fr/wp-json/wp/v2/pages/?slug=politique-de-confidentialite');
  }

  public getContact(): Observable<any> {
    return this.http.get('https://www.cache.jobology.fr/wp-json/wp/v2/pages/?slug=contactez-nous');
  }

  public getAboutUs(): Observable<any> {
    const brand = environment.GlobalVars.brand.toLowerCase();
    const timestamp = new Date().getTime()

    return this.http.get(`https://www.cache.jobology.fr/wp-json/wp/v2/pages/?slug=qui-sommes-nous-${brand}&${timestamp}`);
  }

  // Auto Complete Country - Localité
  public locationAutocomplete = (startWith, countryCode, rows = 10, locationTypesList?) => {
    let locationTypes = [];

    if (locationTypesList) {
      locationTypes = locationTypesList
    }

    return this.apollo.watchQuery<{ locationAutocomplete }>({
      query: gql`query locationAutocomplete($startWith: String!, $countryCode: String, $rows: Int, $locationTypes: [String]) {
          locationAutocomplete(startWith: $startWith, countryCode: $countryCode, rows: $rows, locationTypes: $locationTypes) {
            countryCode
            flagUrl
            id
            latitude
            longitude
            name,
            displayName,
            fullDisplayName
          }
        }`, variables: { startWith: startWith, countryCode: countryCode, rows: rows, locationTypes: locationTypes }
    })
  }

  // Countries List
  public countries = (continentCode?) => {
    let cCode = continentCode ? continentCode : null;

    return this.apollo.watchQuery<{ countries }>({
      query: gql`query countries($continentCode: String) {
          countries(continentCode: $continentCode) {
            countryCode
            flagUrl
            latitude
            id
            longitude
            name
          }
        }`, variables: { continentCode: cCode }
    })
  }

}