import { Injectable } from '@angular/core';
import { BreakpointState, BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable()
export class BreakpointService {
  isDesktop: Observable<BreakpointState>;
  isMobile: Observable<BreakpointState>;

  constructor(private breakpointObserver: BreakpointObserver) {
    this.isDesktop = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Web]);
    // this.isMobile = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.HandsetLandscape, Breakpoints.TabletPortrait]);
    this.isMobile = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]);
  }

  // isDesktop: boolean = this.breakpointObserver.isMatched(Breakpoints.Medium || Breakpoints.Web);
  /*  this.breakpointObserver
   .observe([Breakpoints.Web])
   .subscribe((state: BreakpointState) => {
     if (state.matches) {
       this.isDesktop = true;
       console.log('DESKTOP');
     }
   }); */
}