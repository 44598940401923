import { Component, Inject, Input, Output, Renderer2, ViewChild, ViewEncapsulation, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatListOption } from '@angular/material/list';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { AtsService } from 'src/app/core/services/ats.service';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { PopinService } from 'src/app/core/services/popin.service';
import { UserService } from 'src/app/core/services/user.service';
import * as clone from 'clone';
import { SourcingService } from 'src/app/core/services/sourcing.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { PopinInvitationComponent } from '../popin-invitation/popin-invitation.components';
import { DOCUMENT } from '@angular/common';
import { PermissionsService } from 'src/app/core/services/permissions.service';

@Component({
  selector: 'popin-recruitersList',
  template: '',
  encapsulation: ViewEncapsulation.None
})
export class PopinEntryRecruitersListComponent {
  isMobile: boolean;
  isMobile$;

  id;

  constructor(public dialog: MatDialog, private router: Router, private bk: BreakpointService,
    private ats: AtsService, private actRoute: ActivatedRoute) {

    this.isMobile$ = this.bk.isMobile.subscribe(state => {
      this.isMobile = state.matches;
    });


    this.openDialog();

    this.id = this.router.getCurrentNavigation().extras.state;
  }

  openDialog(): void {
    const className = this.isMobile ? '' : 'isDesktop';

    let dialogRef = this.dialog.open(PopinRecruitersListComponent, {
      id: 'popin-recruitersList',
      // backdropClass: 'popin-candidateDetails-backdrop',
      panelClass: ['popin-recruitersList-panel', className],
      width: this.isMobile ? '100%' : '650px',
      maxWidth: this.isMobile ? '90vw' : 'auto',
      data: this.id,
      autoFocus: false,
    });


    dialogRef.afterClosed().subscribe(result => {
      let newUrl = this.router.url.split('/assigner')[0];
      this.router.navigateByUrl(newUrl);
    });
  }

  ngOnDestroy() {
    this.isMobile$.unsubscribe();
  }

}

@Component({
  selector: 'popin-recruiters-list',
  templateUrl: './popin-recruitersList.component.html',
  styleUrls: ['./popin-recruitersList.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'popin-recruitersList'
  },
})
export class PopinRecruitersListComponent {
  isMobile$;
  isMobile;
  userId;

  usersListForm = new UntypedFormControl();
  usersList: Array<any>;
  usersListFull: Array<any>;
  filteredUsers: Observable<any[]>;

  isLoading = true;

  currentUserDatas;

  @Output() hasChanged = new EventEmitter();
  @Input('title') title;
  @Input('titleAlign') titleAlign;
  @Input('usersListInput') usersListInput;
  @Input('isSelectionList') isSelectionList;
  @Input('multipleRoles') multipleRoles = false;
  @Input('isManagerList') isManagerList;
  @Input('departement') departement;
  @Input('hasPermissions') hasPermissions;
  @Input('inviteDepartement') inviteDepartement;
  @Input('invitePosteAPourvoir') invitePosteAPourvoir;
  @Input('canInviteMembers') canInviteMembers;
  isPopin: boolean;

  constructor(private bk: BreakpointService, public popinService: PopinService, public render: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data, private userService: UserService, @Inject(DOCUMENT) private document,
    private sourcingService: SourcingService, private snack: MatSnackBar,
    public dialogRef: MatDialogRef<PopinEntryRecruitersListComponent>, private auth: AuthMSALService, public dialog: MatDialog,
    private router: Router, private permissionsService: PermissionsService) {
    this.isMobile$ = this.bk.isMobile.subscribe(state => {
      this.isMobile = state.matches;
    });

    this.userId = this.auth.getClaims('sub');

    this.isPopin = Object.keys(this.data).length > 0;

    if (this.isPopin) {
      this.usersList = this.data.usersList;
      this.isSelectionList = this.data.isSelectionList;
      this.title = this.data.title;
      this.multipleRoles = this.data.multipleRoles;
      this.isManagerList = this.data.isManagerList;
      this.departement = this.data.departement;
      this.hasPermissions = this.data.hasPermissions;
      this.inviteDepartement = this.data.inviteDepartement;
      this.invitePosteAPourvoir = this.data.invitePosteAPourvoir;
      this.canInviteMembers = this.data.canInviteMembers;

    } else {
      this.usersList = this.usersListInput;
    }

  }

  interfacePosteAPourvoir;
  permissions$;
  permissionsList;
  ngOnInit(): void {
    this.interfacePosteAPourvoir = this.router.url.includes('/sourcing/postes');
    //this.popinService.handleHeightScroll(this.render);
    this.userService.getRecruteur(this.userId).pipe(take(1)).subscribe(result => {
      if (result) {
        this.currentUserDatas = result;
      }
    })

    if (this.isPopin) {
      this.getUsersList();
    }

    this.filteredUsers = this.usersListForm.valueChanges
      .pipe(
        startWith(''),
        //map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : this.allMembersList?.slice())
      );

    this.handlePermissions();

  }

  handlePermissions() {

    this.permissionsService.getPermissions(this.userService.inviteRecruteur(this.userService.getTenantActive().id, { invitation: true }, true));
    this.permissions$ = this.permissionsService.ngxPerm.permissions$.subscribe(result => {
      if (result) {
        this.permissionsList = this.permissionsService.ngxPerm.getPermissions()
      }

      if (result?.invitation) {
        this.canInviteMembers = true;
      }
    })
  }

  alreadyInit = false;
  ngOnChanges(changes) {
    this.resetFocusInList();

    if (!!this.departement && !this.alreadyInit && !this.isPopin) {
      this.alreadyInit = true;
      this.getUsersList();
    }

    if (changes?.isSelectionList?.currentValue) {
      this.getAllMembers();
    }
  }

  resetFocusInList() {
    // Reset focus in the list from autocompletion
    this.document.querySelectorAll('.mat-list-item').forEach(el => {
      el.classList.remove('focus')
    })

  }
  inviteMember() {
    this.resetFocusInList();

    let dialogRef = this.dialog.open(PopinInvitationComponent, {
      id: 'popin-invitationt',
      panelClass: ['popin-invitation'],
      width: this.isMobile ? '100%' : '650px',
      maxWidth: this.isMobile ? '90vw' : 'auto',
      data: {
        departement: this.departement,
        roles: this.roles,
        userDatas: { sub: this.userId },
        inviteDepartement: this.inviteDepartement,
        invitePosteAPourvoir: this.invitePosteAPourvoir,
        posteAPourvoir: this.data.posteAPourvoir,
        usersList: this.allMembersChecked
      },
      autoFocus: false,
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe(result => {

      if (result?.membersAdded) {
        this.getUsersList();
      }

    })
  }

  getUsersList() {
    // if (this.fetchUsers) {
    //   let list = this.usersList;
    //   this.usersList = [];

    //   list.map((user, index) => {
    //     this.userService.getRecruteur(user.recruteurId).pipe(take(1)).subscribe(result => {
    //       if (result) {
    //         let datas = clone(result);
    //         this.usersList.push(result);
    //         //this.usersListText = 'Comprend' + (index + 1) + ' membres'
    //       }

    //       if (index + 1 == list.length) {
    //         this.allMembersList = this.usersList
    //         this.getRoles(this.allMembersList);
    //       }
    //     })
    //   })
    // } 

    // Poste a Pourvoir
    if (!this.departement) {
      this.sourcingService.getPosteMembers(this.data.posteAPourvoir.id, 1, 100).pipe(take(1)).subscribe(res => {

        if (res) {

          const datas = clone(res);
          this.usersList = datas.data

          // If selection list, current user has permission then get all members
          if (this.isSelectionList) {
            this.getAllMembers();
          } else {
            this.getRoles(this.usersList);
          }


        }
      }, err => {
        if (this.isPopin) {
          this.dialogRef.close();
        }

        this.snack.open(`[${err.status}] Une erreur est survenue. La liste des membres n\'a pas pu se charger.`, 'OK', { duration: 0, panelClass: 'error' })
      })
    } else { // Departement
      let departementRoleId;

      if (this.isManagerList) {
        departementRoleId = 5
      }

      this.sourcingService.getDepartementMembers(this.departement.id, 100, null, departementRoleId).pipe(take(1)).subscribe(res => {

        if (res) {

          const datas = clone(res);
          this.usersList = datas.data
          // console.log(this.usersList)

          // While creating private/confidential department, precheck the manager in the list
          if (this.data.isEmpty) {
            this.usersList.push({
              departementRoleId: 5,
              recruteurRoleId: 2,
              recruteur: this.currentUserDatas
            })
          }

          // If selection list, current user has permission then get all members
          if (this.isSelectionList) {

            this.getAllMembers();
          } else {
            this.getRoles(this.usersList);
          }


        }
      }, err => {
        if (this.isPopin) {
          this.dialogRef.close();
        }

        this.snack.open(`[${err.status}] Une erreur est survenue. La liste des membres n\'a pas pu se charger.`, 'OK', { duration: 0, panelClass: 'error' })
      })
    }
  }

  allMembersList;
  allMembersCount;
  allMembersChecked;
  getAllMembers() {

    this.sourcingService.getAllMembers().pipe(take(1)).subscribe(res => {
      if (res) {
        const datas = clone(res);

        this.allMembersList = datas;
        //this.allMembersCount = datas.length;

        // Sort all list when removing members
        this.allMembersList.sort((a, b) => {
          if ((a.prenom + ' ' + a.nom) < (b.prenom + ' ' + b.nom)) {
            return -1
          }

          if ((a.prenom + ' ' + a.nom) > (b.prenom + ' ' + b.nom)) {
            return 1;
          }
          return 0;
        })

        let memberChecked = [];

        // If poste à pourvoir, remove "membre désactivé" from list
        for (let i = this.allMembersList?.length - 1; i >= 0; i--) {
          if (this.data.context === 'poste-a-pourvoir' || this.data.posteAPourvoir) {
            if (this.allMembersList[i]?.statut.toLowerCase() === 'désactivé') {
              this.allMembersList.splice(i, 1);
            }
          }
        }
        this.allMembersCount = this.allMembersList.length;


        for (let i = this.allMembersList?.length - 1; i >= 0; i--) {
          // If option empty is available, check current user by default but keep list empty
          // if (this.data.isEmpty && (this.allMembersList[i].id === this.userId)) {
          //   this.allMembersList[i].checked = true
          // }

          this.usersList.forEach(userIn => {

            if (userIn.recruteur?.id === this.allMembersList[i]?.id && !this.data.isEmpty) {

              this.allMembersList[i].checked = true;

              if (this.departement) {
                this.allMembersList[i].departementRoleId = userIn.departementRoleId;
                this.allMembersList[i].recruteurRoleId = userIn.recruteurRoleId;
              } else {
                this.allMembersList[i].recruteurRoleId = userIn.roleId;
              }

              memberChecked.unshift(this.allMembersList[i])
              this.allMembersList.splice(i, 1);

            } else {

              // If option empty is available, check current user by default but keep list empty
              if (this.allMembersList[i]?.id === this.userId && this.data.isEmpty) {

                this.allMembersList[i].checked = true

                if (this.departement) {
                  this.allMembersList[i].departementRoleId = userIn.departementRoleId;
                  this.allMembersList[i].recruteurRoleId = userIn.recruteurRoleId;
                } else {
                  this.allMembersList[i].recruteurRoleId = userIn.roleId;
                }

                memberChecked.unshift(this.allMembersList[i])
                this.allMembersList.splice(i, 1);

              }

            }


          })
        }

        this.allMembersChecked = memberChecked;
        this.allMembersList = [].concat(memberChecked, this.allMembersList)

        //if (!this.isManagerList) {
        this.getRoles(this.allMembersList);
        //}


        this.isLoading = false;

      }
    }, err => {
      if (this.isPopin) {
        this.dialogRef.close();
      }

      this.snack.open(`[${err.status}] Une erreur est survenue. La liste des membres n\'a pas pu se charger.`, 'OK', { duration: 0, panelClass: 'error' })
    })
  }

  handleClick(event, checkbox, datas) {
    this.resetFocusInList();

    if (!this.hasPermissions || checkbox.checked && this.departement.typeDepartementId === 1 || this.isManagerList && this.departement.countPosteAPourvoir !== 0 && this.allMembersChecked.length === 1) {
      event.preventDefault();
      return false;
    } else {
      checkbox.checked = !checkbox.checked
    }

    this.updateUser({
      checked: checkbox.checked
    },
      {
        recruteurId: datas.recruteurId,
        recruteurRoleId: datas.recruteurRoleId,
        departementId: datas.departementId
      })
  }

  handleTooltip(user) {
    if (!this.isManagerList && user?.checked && this.departement?.typeDepartementId === 1) {
      return 'Vous ne pouvez pas supprimer un membre d\'un département public'
    }

    if (!this.isManagerList && user?.departementRoleId === 5 && this.data.context !== 'poste-a-pourvoir') {
      return 'Vous ne pouvez pas supprimer ce membre car il est responsable du département'
    }

    // Cannot remove the last manager if 1 post remains
    if (this.isManagerList && this.departement.countPosteAPourvoir !== 0 && this.allMembersChecked.length === 1 && user.checked) {
      return 'Vous ne pouvez pas supprimer le dernier responsable, car le département contient au moins un poste à pourvoir'
    }

    // if (user.statut?.toLowerCase() === 'invitation en attente' || user.statut?.toLowerCase() === 'invitation expirée') {
    //   return 'Vous ne pouvez pas assigner un membre qui n\'a pas accepté l\'invitation';
    // }

  }

  trackByFn(index, item) {
    return index;
  }

  isMember = [];
  changes = false;
  isSubmitting = false;
  updateUser(event, userDatas) {
    this.resetFocusInList();

    // Poste a pourvoir
    if (this.data.posteAPourvoir || this.data.context === 'poste-a-pourvoir') {
      let infosPost = {
        postId: this.data.posteAPourvoir.id,
        recruteurId: userDatas.recruteurId,
        roleId: userDatas.recruteurRoleId
      }

      if (event.checked) {
        this.sourcingService.addPosteMember(infosPost).pipe(take(1)).subscribe(res => {
          this.changes = true;
          this.hasChanged.emit('true');

          if (res) {
            let datas = clone(res);

            // Sort list with checked members on the top
            this.sortMembers(datas, true)

            this.snack.open('Le membre a bien été ajouté. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 })

            setTimeout(() => {
              this.isSubmitting = false;
            }, 3500)
          }
        })
      }

    }

    // Departement
    if (this.data.departement) {
      // If departement is public and isn't managers list
      if (!event.checked && this.departement?.typeDepartementId === 1 && !this.isManagerList) {
        return false
      }

      let infos = {
        ...userDatas,
        departementRoleId: this.isManagerList ? 5 : 6
      }

      this.isSubmitting = true;

      if (event.checked) {
        this.sourcingService.putDepartementMember(infos).pipe(take(1)).subscribe(res => {
          // console.log('add member', res)

          this.changes = true;
          this.hasChanged.emit('true')

          if (res) {
            let datas = clone(res);

            // Sort list with checked members on the top
            this.sortMembers(datas, true)

            this.snack.open('Le membre a bien été ajouté. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 })

            setTimeout(() => {
              this.isSubmitting = false;
            }, 3500)
          }
        }, err => {
          this.isSubmitting = false;
        })

      } else {

        // Use PUT to remove manager from manager list, because we want him to stay member of the department
        if (this.isManagerList) {

          // Cannot remove the last manager if 1 post remains
          if (this.departement.countPosteAPourvoir !== 0 && this.allMembersChecked.length === 1) {
            this.snack.open('Vous ne pouvez pas supprimer', 'OK', { duration: 2500, panelClass: 'error' });
            return false;
          }

          infos.departementRoleId = 6;

          this.sourcingService.putDepartementMember(infos).pipe(take(1)).subscribe(res => {
            console.log('remove')

            this.changes = true;
            this.hasChanged.emit('true')

            // Sort list with checked members on the top
            this.sortMembers(userDatas, false)

            this.snack.open('Le responsable a bien été supprimé. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 });

            setTimeout(() => {
              this.isSubmitting = false;
            }, 3500)
          }, err => {
            this.isSubmitting = false;
          })
        } else {
          this.sourcingService.deleteDepartementMember(userDatas).pipe(take(1)).subscribe(res => {
            console.log('delete')

            this.changes = true;
            this.hasChanged.emit('true')

            // Sort list with checked members on the top
            this.sortMembers(userDatas, false)

            this.snack.open('Le membre a bien été supprimé. Un email de notification lui a été envoyé.', 'OK', { duration: 2500 });

            setTimeout(() => {
              this.isSubmitting = false;
            }, 3500)
          }, err => {
            this.isSubmitting = false;
          })
        }

      }
    }
  }

  checkedUser(event, user) {

    this.allMembersList.map(el => {
      if (user.id === el.id) {
        user.checked = event.checked;

        if (this.departement) {
          user.departementRoleId = this.isManagerList ? 5 : 6
        }

        this.sortMembers(el, event.checked)
      }
    })

  }

  updateUsers() {
    this.isSubmitting = true;
    let membersChecked = [];


    this.allMembersList.map(el => {
      if (el.checked) {

        if (this.departement && this.data.context !== 'poste-a-pourvoir') {

          membersChecked.push({
            recruteurId: el.id,
            recruteurRoleId: el.rolesForm.value,
            departementRoleId: el.departementRoleId
          });
        } else {
          membersChecked.push({
            recruteurId: el.id,
            roleId: el.rolesForm.value,
          });
        }

      }
    })

    // Not allowed to have 0 member on a post job
    if (membersChecked.length === 0 && this.interfacePosteAPourvoir) {
      this.isSubmitting = false;

      this.snack.open('Vous devez avoir au moins 1 membre affecté au poste à pourvoir.', 'OK', { duration: 0, panelClass: 'error' })
      return false;
    }

    if (this.departement && this.data.context !== 'poste-a-pourvoir') {

      this.sourcingService.updateDepartementMembers(this.departement.id, membersChecked, false, this.isManagerList).pipe(take(1)).subscribe(res => {

        this.changes = true;
        this.hasChanged.emit('true');

        setTimeout(() => {
          this.isSubmitting = false;

          this.snack.open('Les membres du département ont bien été modifiés. Un email de notification leur a été envoyé.', 'OK', { duration: 2500 })

          this.dialogRef.close({ success: true });
        }, 3500)


      }, err => {
        this.isSubmitting = false;
      })
    }

    if (this.data.posteAPourvoir || this.data.context === 'poste-a-pourvoir') {
      this.sourcingService.updatePosteMembers(this.data.posteAPourvoir.id, membersChecked).pipe(take(1)).subscribe(res => {
        this.changes = true;
        this.hasChanged.emit('true');


        setTimeout(() => {
          this.isSubmitting = false;

          this.snack.open('Les membres du poste à pourvoir ont bien été modifiés. Un email de notification leur a été envoyé.', 'OK', { duration: 2500 })

          this.dialogRef.close({ success: true });
        }, 3500)

      }, err => {
        this.isSubmitting = false;
      })
    }
  }

  sortMembers(user?, checked?) {
    let memberChecked = [];

    if (user) {
      for (let i = this.allMembersCount - 1; i >= 0; i--) {
        if (this.allMembersList[i].id === user.recruteurId) {
          this.allMembersList[i].checked = checked;

        }
      }

      for (let i = this.allMembersCount - 1; i >= 0; i--) {
        if (this.allMembersList[i].checked) {
          memberChecked.unshift(this.allMembersList[i])
          this.allMembersList.splice(i, 1);
        }
      }

    }

    // Sort members checked
    memberChecked.sort((a, b) => {
      if ((a.prenom + ' ' + a.nom) < (b.prenom + ' ' + b.nom)) {
        return -1
      }

      if ((a.prenom + ' ' + a.nom) > (b.prenom + ' ' + b.nom)) {
        return 1;
      }
      return 0;
    })

    this.allMembersChecked = memberChecked;

    // Sort all list when removing members
    this.allMembersList.sort((a, b) => {
      if ((a.prenom + ' ' + a.nom) < (b.prenom + ' ' + b.nom)) {
        return -1
      }

      if ((a.prenom + ' ' + a.nom) > (b.prenom + ' ' + b.nom)) {
        return 1;
      }
      return 0;
    })

    this.allMembersList = [].concat(memberChecked, this.allMembersList);
  }

  roles;
  getRoles(users?) {
    this.sourcingService.getRoles('poste-a-pourvoir').pipe(take(1)).subscribe(result => {
      // this.sourcingService.getRoles().pipe(take(1)).subscribe(result => {
      if (result) {
        this.roles = result;
        this.isLoading = false;
      }
    })

    if (users) {
      users.forEach(user => {
        // If multiple roles list available
        let rolesList = [];
        let roleList;

        if (user.recruteurRoleId === undefined) {
          // this.multipleRoles ? rolesList.push(2) : roleList = 2;

          if (user.roles?.length) {
            // Admin 
            if (user.roles[0].id === 1 || user.roles[0].id === 4) {
              this.multipleRoles ? rolesList.push(2) : roleList = 2;
            } else {
              this.multipleRoles ? rolesList.push(user.roles[0].id) : roleList = user.roles[0].id;
            }
          } else {
            if (user.roleId === 1 || user.roleId === 4) {
              this.multipleRoles ? rolesList.push(2) : roleList = 2;
            } else {
              this.multipleRoles ? rolesList.push(user.roleId) : roleList = user.roleId;
            }
          }



        } else {

          this.multipleRoles ? rolesList.push(user.recruteurRoleId) : roleList = (user.recruteurRoleId === 1 ? 2 : user.recruteurRoleId);
        }

        this.multipleRoles ? user.rolesForm = new UntypedFormControl(rolesList) : user.rolesForm = new UntypedFormControl(roleList);

      })

      this.allMembersList = users
    }
  }

  updateRoles(event, user) {
    this.resetFocusInList();

    let datas = {
      departementId: this.departement.id,
      recruteurId: user.id,
      recruteurRoleId: event.value
    }

    if (user.checked) {
      this.changes = true;
      this.hasChanged.emit('true')

      this.sourcingService.updateDepartementMember(datas).pipe(take(1)).subscribe(result => {
        this.snack.open(`Le rôle de ${user.prenom} ${user.nom} a été mis à jour`, 'OK', { duration: 2000 })
      })
    } else {
      this.snack.open(`${user.prenom} ${user.nom} ne fait pas partie du département`, 'OK', { duration: 3500, panelClass: 'error' })
    }

  }

  displayFn(user): string {

    return user && user.name ? user.name : '';
  }

  private _filter(name): Array<any> {
    this.resetFocusInList();

    const filterValue = name.toLowerCase();

    //this.usersList = this.usersList ? this.usersList : this.usersListInput;

    return this.allMembersList.filter(user => {

      if (user.nom && user.prenom) {
        let fullname = user?.prenom.toLowerCase() + ' ' + user?.nom.toLowerCase()
        return (user?.nom.toLowerCase().indexOf(filterValue) === 0 || user?.prenom.toLowerCase().indexOf(filterValue) === 0)
          || (fullname == filterValue)
      }

      if (user?.recruteur?.nom && user.recruteur?.prenom) {
        let fullname = user?.recruteur?.prenom.toLowerCase() + ' ' + user?.recruteur?.nom.toLowerCase()
        return (user?.recruteur?.nom.toLowerCase().indexOf(filterValue) === 0 || user?.recruteur?.prenom.toLowerCase().indexOf(filterValue) === 0)
          || (fullname == filterValue)
      }

      if (!user.nom || !user.recruteur?.nom) {
        let fullname = user?.recruteur?.prenom ? user?.recruteur?.prenom.toLowerCase() : (user?.prenom ? user?.prenom?.toLowerCase() : '')
        return user?.prenom?.toLowerCase().indexOf(filterValue) === 0 || (fullname == filterValue)
      }

    });


  }

  clear(event?) {
    this.resetFocusInList();

    if (event) {
      event.preventDefault();
    }

    this.usersListForm.setValue('');
  }

  @ViewChild('usersSelection', { static: false }) usersSelection;
  //@ViewChild('usersSelection', { static: false }) usersSelection: MatSelectionList;
  autoCompleteSelectedId;
  autoCompleteOptionSelected(event) {
    this.resetFocusInList();

    this.autoCompleteSelectedId = event.id;

    //console.log(this.usersSelection._elementRef.nativeElement.querySelector(`[userId="${this.autoCompleteSelectedId}"]`))

    let element = this.usersSelection._elementRef.nativeElement.querySelector(`[userId="${this.autoCompleteSelectedId}"]`);

    if (this.isPopin) {
      element.scrollIntoView()
    } else {
      //console.log(element.getBoundingClientRect().top);
      this.router.navigate([], { fragment: this.autoCompleteSelectedId });
    }

    this.render.addClass(element, 'focus')

    // Reset input autocompletion
    this.usersListForm.setValue('');


    // if (this.isSelectionList) {
    //   let elementCheckbox = element.querySelector('mat-checkbox input');
    //   elementCheckbox.click()
    // }

    // this.selectionChange(this.usersSelection.selectedOptions.selected);
  }

  selectedList = [];
  selectionChange(options: MatListOption[]) {
    this.selectedList = [];
    options.map(o => {
      this.selectedList.push(o._text.nativeElement.textContent + ', ');
    });

  }

  ngOnDestroy() {
    this.isMobile$.unsubscribe();
  }

}
