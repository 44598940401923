import { Injectable } from '@angular/core';

import { NgxRolesService, NgxPermissionsService } from 'ngx-permissions';
import { AtsService } from './ats.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject, take, map, distinctUntilChanged, shareReplay } from 'rxjs';
import { environment } from '@env';
import { Router } from '@angular/router';
//import { HttpLink } from '@apollo/client';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  // checkPermission(user: Permissions.User, feature: Permissions.Features, permission: Permissions.Permission): boolean {
  //   const featurePermission = user.featurePermissions.find(f => f.feature === feature);

  //   if (!!featurePermission) {
  //     switch (permission) {
  //       case Permissions.Permission.Create:
  //         return featurePermission.permission !== Permissions.Permission.None;
  //       case Permissions.Permission.Read:
  //         return featurePermission.permission !== Permissions.Permission.None;
  //       case Permissions.Permission.Update:
  //         return featurePermission.permission !== Permissions.Permission.None;
  //       case Permissions.Permission.Delete:
  //         return featurePermission.permission !== Permissions.Permission.None;
  //       case Permissions.Permission.Search:
  //         return featurePermission.permission !== Permissions.Permission.None;
  //       case Permissions.Permission.Admin:
  //         return featurePermission.permission === Permissions.Permission.Admin;
  //     }
  //   }
  //   return false;
  // }


  getPermissionsResponse$: Subject<any> = new Subject();
  routesAccessList = [
    //{ reqName: 'membres', url: '/parametres/membres' },
    { reqName: 'profil', url: '' },
    // { reqName: 'createDiffusion', url: '/canaux' },
    //{ reqName: 'getSociete', url: '/utilisateur/mon-compte/ma-societe' },
  ]

  constructor(private rolesService: NgxRolesService, public ngxPerm: NgxPermissionsService,
    private ats: AtsService, private router: Router) {

    this.getPermissionsResponse$.subscribe(result => {
      if (result) {

        // Store only permissions with status 200
        if (result.status === 200) {
          this.ngxPerm.addPermission([result.name])
        }

        // If request is for accessing a route, prevent access like Guard behavior
        if (result.status === 403 && result.preAuth) {

          // Check if request and path url match with route access
          this.routesAccessList.map(el => {
            if (el.reqName === result.name && this.router.url.includes(el.url)) {
              this.router.navigate(['/mon-compte/acces-refuse'])
            }
          });


        }
      }

    })

    // this.ngxPerm.permissions$.subscribe(result => {
    //   this.permissionsList = result;
    //   console.log('perm', result)
    // })

  }

  getPermissions(APIRequest, refetch?) {

    // SIMPLE QUERY
    if (APIRequest.valueChanges?.source) {
      return APIRequest.valueChanges.pipe(take(1)).subscribe();
    } else { // QUERY IS A MUTATION
      return APIRequest.pipe(take(1)).subscribe();
    }


    //this.rolesService.addRole(this.responseResult.name);
  }

  checkRoutePermissions(routePermissions, permissionsList) {
    let access;
    routePermissions.forEach((element) => {
      // console.log('routeperm', routePermissions)
      // console.log('perm', permissionsList)

      if (permissionsList.hasOwnProperty(element)) {

        access = true;
        return true
      } else {
        access = false;
      }
    })

    // console.log(access)
    if (!access) {
      //this.router.navigate(['/mon-compte/acces-refuse'])
    }
  }



}