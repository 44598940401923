import { Apollo } from 'apollo-angular';
import { HttpLinkHandler, HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { BrowserModule, BrowserTransferStateModule, HammerModule, HAMMER_LOADER, makeStateKey, TransferState } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing-jobology.module';
import { AppComponent } from './app-jobology.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationModule } from './pages/authentication/authentication.module';


import { PopinComponent } from './components/popin/popin.component';
import { ScrollTopComponent } from './components/scrolltop/scrolltop.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { JwtInterceptor } from './core/interceptors/jwt.interceptor';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NotfoundComponent } from './pages/notfound/notfound.component';
import { environment } from '@env';
import { HeaderModule } from './components/header/header.module';

import { NgxFaviconModule } from './lib/ngx-favicon/ngx-favicon.module';
import { customFavicons, AvailableCustomFavicons } from '../favicon.config';
import { FooterModule } from './components/footer/footer.module';
import { MatTooltipModule } from '@angular/material/tooltip';


import { HeaderService } from './core/services/header.service';
import { ScriptLoaderService } from './core/services/scriptloader.service';
import { BreakpointService } from './core/services/breakpoints.service';
import { RouterNavService } from './core/services/router-nav.service';
import { IconsService } from './core/services/icons.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexLayoutModule, MediaMarshaller } from '@angular/flex-layout';
import { AuthService } from './core/services/auth.service';
import { UserService } from './core/services/user.service';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';

import { TransferHttpCacheModule } from '@nguniversal/common';
import { msalConfig } from './auth.config.ts';
import { MatButtonModule } from '@angular/material/button';
import { AuthMSALService } from './core/services/auth-msal.service';
import { MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { PermissionsInterceptor } from './core/interceptors/permissions.interceptor';
import { PermissionsModule } from './components/permissions/permissions.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShepherdService } from 'angular-shepherd';
import { PopinSubscriptionComponent } from './components/popin/popin-subscription/popin-subscription.component';
import { PopinSubscriptionModule } from './components/popin/popin-subscription/popin-subscription.module';
import { PopinForbiddenModule } from './components/popin/popin-forbidden/popin-forbidden.module';
import { AtsService } from './core/services/ats.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AtsNavService } from './core/services/ats-nav.service';


registerLocaleData(localeFr, 'fr');

const STATE_KEY = makeStateKey('datas.state');

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    PopinComponent,
    NotfoundComponent,
    ScrollTopComponent,
    //PopinSubscriptionComponent
  ],
  imports: [
    BrowserTransferStateModule,
    BrowserModule.withServerTransition({ appId: 'app' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    TransferHttpCacheModule,
    HttpClientModule,
    // HttpLinkModule,
    AuthenticationModule,
    // SharedModule.forRoot(),
    HeaderModule,
    FooterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.name == 'prod' || environment.name == 'preprod', registrationStrategy: 'registerImmediately'
    }),
    NgxFaviconModule.forRoot<AvailableCustomFavicons>({
      faviconElementId: 'favicon',
      defaultUrl: 'favicon.ico',
      custom: customFavicons,
    }),
    MatTooltipModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatDialogModule,
    FlexLayoutModule,
    HammerModule,
    MatButtonModule,
    MsalModule,
    MatMomentDateModule,
    NgxPermissionsModule.forRoot(),
    PopinSubscriptionModule,
    PopinForbiddenModule,
    MatSnackBarModule
  ],
  providers: [
    AtsNavService,
    HeaderService,
    ScriptLoaderService,
    BreakpointService,
    RouterNavService,
    IconsService,
    AuthService,
    UserService,
    // ErrorsService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: PermissionsInterceptor, multi: true },
    AuthMSALService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    /* {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    }, */
    MsalGuard,
    MsalService,
    // MsalBroadcastService
    /*  {
       provide: HAMMER_LOADER,
       useValue: async () => {
         return import('hammerjs/hammer');
       }
     } */
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})


export class AppModule {
  cache: InMemoryCache;
  link: HttpLinkHandler;

  constructor(private readonly apollo: Apollo, private readonly transferState: TransferState,
    private readonly httpLink: HttpLink, @Inject(PLATFORM_ID) readonly platformId: Object, m: MediaMarshaller) {
    // HACK FOR -PRINT- BREAKPOINTS ISSUE until resolve: https://github.com/angular/flex-layout/issues/1201
    // @ts-ignore
    m.subject.subscribe((x) => {
      // @ts-ignore
      if (m.activatedBreakpoints.filter((b) => b.alias === 'print').length === 0) {
        // @ts-ignore
        this.lastValue = [...m.activatedBreakpoints];
      } else {
        // @ts-ignore
        m.activatedBreakpoints = [...this.lastValue];
        // @ts-ignore
        m.hook.collectActivations = () => { };
        // @ts-ignore
        m.hook.deactivations = [...this.lastValue];
      }
    });

    const isBrowser = this.transferState.hasKey<any>(STATE_KEY);

    this.cache = new InMemoryCache({
      typePolicies: {
        PosteApourvoir: {
          fields: {
            annotationsContrat: {
              merge(existing, incoming) {
                return incoming
              }
            },
            annotationsNiveauEtude: {
              merge(existing, incoming) {
                return incoming
              }
            },
            annotationsNiveauExperience: {
              merge(existing, incoming) {
                return incoming
              }
            },
            annotationsTacheTechnique: {
              merge(existing, incoming) {
                return incoming
              }
            }
          },
        },
        ModeleFluxRecrutement: { merge: true },
        EtapeFluxRecrutement: { merge: true },
        ProfilEtapeFluxRecrutement: { merge: true }
      }
    });

    this.link = this.httpLink.create({ uri: environment.path.api });

    apollo.create({
      link: this.link,
      cache: this.cache,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      },
      // queries with `forceFetch` enabled will be delayed
      // ssrForceFetchDelay: 200,
      ssrMode: true
    });

    if (isBrowser) {
      this.onBrowser();
    } else {
      this.onServer();
    }
  }

  onServer() {
    // serializes the cache and puts it under a key
    this.transferState.onSerialize(STATE_KEY, () => {
      return this.cache.extract();
    });
  }

  onBrowser() {
    // reads the serialized cache
    const state = this.transferState.get<any>(
      STATE_KEY,
      null,
    );

    // and puts it in the Apollo
    this.cache.restore(state);
  }

}

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

/* export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
} */

