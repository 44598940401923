import { Component, OnInit, ViewEncapsulation, Input, Optional, Renderer2, ElementRef } from '@angular/core';
import { BreakpointService } from 'src/app/core/services/breakpoints.service';
import { Subscription } from 'rxjs';
import { environment } from '@env';
import { Router, NavigationEnd } from '@angular/router';
import { FooterService } from 'src/app/core/services/footer.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  @Input('showSecondTheme') rtTheme: boolean;

  environment = environment;
  public isMobile: boolean;
  routerSub$: Subscription;
  cvPath: string;
  cvTitle: string
  isRecruiters: boolean;

  alphabets: Array<string> = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n',
    'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

  cities: Array<any> = [
    { id: '3038033', name: 'Alsace' },
    { id: '3037350', name: 'Aquitaine' },
    { id: '3035876', name: 'Auvergne' },
    { id: '3034693', name: 'Basse-Normandie' },
    { id: '3037350', name: 'Bourgogne' },
    { id: '3030293', name: 'Bretagne' },
    { id: '3027939', name: 'Centre' },
    { id: '3027257', name: 'Champagne-Ardenne' },
    { id: '3023519', name: 'Corse' },
    { id: '3017372', name: 'Franche-Comté' },
    { id: '3013756', name: 'Haute-Normandie' },
    { id: '3012874', name: 'Île-de-France' },
    { id: '3007670', name: 'Languedoc-Roussillon' },
    { id: '2998268', name: 'Limousin' },
    { id: '2997551', name: 'Lorraine' },
    { id: '2993955', name: 'Midi-Pyrénées' },
    { id: '2990119', name: 'Nord-Pas-de-Calais' },
    { id: '2988289', name: 'Pays de la Loire' },
    { id: '2987375', name: 'Picardie' },
    { id: '2986492', name: 'Poitou-Charentes' },
    { id: '2985244', name: 'Provence-Alpes-Côte d\'Azur' },
    { id: '2983751', name: 'Rhône-Alpes' },
  ];

  year = new Date().getFullYear();
  displayFooter: boolean = true;

  supportUrl = environment.GlobalVars.brand === 'JT_FR' ? `https://support.${environment.GlobalVars.name}.com` : `https://support.${environment.GlobalVars.name}.fr`;

  constructor(public breakpoints: BreakpointService, private router: Router, private footerService: FooterService,
    private render: Renderer2, private el: ElementRef) {
    this.breakpoints.isMobile.subscribe((state) => {
      this.isMobile = state.matches;
    });

    this.routerSub$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isRecruiters = true;
        this.cvPath = '/espace-recruteurs/recherche-cv';
        this.cvTitle = 'CV'


        // Display FOOTER or not
        this.footerService.display.subscribe((display) => {
          this.displayFooter = display;

          if (!display) {
            this.render.addClass(this.el.nativeElement, 'not-display')
          } else {
            this.render.removeClass(this.el.nativeElement, 'not-display')
          }
        })

      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe();

  }
}
