import { Component, OnInit } from '@angular/core';
// import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { HeaderService } from 'src/app/core/services/header.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env';
import { AuthMSALService } from 'src/app/core/services/auth-msal.service';
import { FooterService } from 'src/app/core/services/footer.service';
import { PermissionsService } from 'src/app/core/services/permissions.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  //setUrlParam: string = this.actRoute.snapshot.queryParamMap.get('set');
  // returnUrlParam: string = this.actRoute.snapshot.queryParamMap.get('returnurl');
  usernameParam: string = this.actRoute.snapshot.queryParamMap.get('username');
  setUrlParam = decodeURIComponent((<any>window).location.search.split('&set=')[1]);
  returnUrlParam;

  showDiv = false;
  showSessionExpired = false;

  constructor(private actRoute: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService, private cookie: CookieService,
    private router: Router, public auth: AuthMSALService, private footerService: FooterService,
    private permissionsService: PermissionsService) {

  }

  pwaIsActive = (<any>window).matchMedia('(display-mode: standalone)').matches;

  ngOnInit() {
    this.footerService.display.next(false);

    /*if (this.returnUrlParam) {
      sessionStorage.setItem('returnurlparam', this.returnUrlParam);
 
      setTimeout(() => {
 
        const param = `set=${this.setUrlParam}`;
        const url: string = `/espace-recruteurs/authenticate/login?${param}`;
 
        sessionStorage.setItem('set', url);
        this.oidcSecurityService.logoff();
      }, 600);
    } else {
      this.authService.logout();
    }
 */

    /* if (environment.name !== 'dev' && this.cookie.get('referer') && this.cookie.get('referer') !== 'http://localhost:4000/') {
      (<any>window).location.href = `${this.cookie.get('referer')}espace-recruteurs/accueil/ctl/logoff.aspx`
    } else {
      this.showDiv = true;
    } */

    (<any>sessionStorage).removeItem('initUrl');
    (<any>localStorage).removeItem('newUser');

    localStorage.removeItem('token');
    localStorage.removeItem('tenants');
    localStorage.removeItem('tenantActive');
    localStorage.removeItem('subscriptionOn');
    localStorage.removeItem('applyCSM');
    localStorage.removeItem('displayAdminNav');
    localStorage.clear();
    sessionStorage.clear();

    this.permissionsService.ngxPerm.flushPermissions();


    // If token expired
    if ((<any>sessionStorage).getItem('tokenExpired') == 'true') {
      this.showDiv = true;
      this.showSessionExpired = true;
      (<any>sessionStorage).removeItem('tokenExpired')

      return
    }

    if (environment.name !== 'dev' && (<any>sessionStorage).getItem('sourceUrl') !== null && !this.pwaIsActive) {
      this.formatSourceUrl();
      (<any>window).location.href = `${this.sourceUrl}espace-recruteurs/accueil/ctl/logoff.aspx`
    } else {
      this.showDiv = true;
    }
  }



  sourceUrl;
  formatSourceUrl() {
    this.sourceUrl = `https://${(<any>sessionStorage).getItem('sourceUrl')}/`;
    let url = (<any>sessionStorage).getItem('sourceUrl');

    // Rewrite URL for PREPROD
    if (!environment.production) {
      this.sourceUrl = url.includes('www') ? `https://preprod${url.split('www')[1]}/` : this.sourceUrl;
    }

    return this.sourceUrl;
  }

}
