import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';

import { AtsService } from 'src/app/core/services/ats.service';

import { MatDialogModule } from '@angular/material/dialog';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { IconsService } from 'src/app/core/services/icons.service';
import { MatSelectModule, MAT_SELECT_CONFIG } from '@angular/material/select';
import { SkeletonModule } from 'src/app/components/skeleton/skeleton.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PermissionsModule } from 'src/app/components/permissions/permissions.module';


import { PopinInvitationComponent } from './popin-invitation.components';



@NgModule({
  declarations: [
    PopinInvitationComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatTableModule,
    MatMenuModule,
    MatDialogModule,
    LoadingModule,
    MatTooltipModule,
    MatChipsModule,
    SkeletonModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    LoadingModule,
    MatIconModule,
    PermissionsModule
  ],
  exports: [
    PopinInvitationComponent
  ]
})
export class PopinInvitationModule {
  constructor() {

  }
}

