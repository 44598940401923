import { Injectable } from '@angular/core';
import {
  HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse, HttpErrorResponse, HttpEvent
} from '@angular/common/http';

import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { ErrorsService } from '../services/errors.service';
import { EventEmitter } from 'protractor';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { map, take, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { tokenRequest } from 'src/app/auth.config.ts';
import { AuthMSALService } from '../services/auth-msal.service';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { headersRequest } from '../../auth.config.ts';
import { MatDialog } from '@angular/material/dialog';
import { PopinSubscriptionComponent } from 'src/app/components/popin/popin-subscription/popin-subscription.component';
import { PopinForbiddenComponent } from 'src/app/components/popin/popin-forbidden/popin-forbidden.component';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
  idToken: any;
  accessToken: any;
  jwt;
  tenantId = '';

  constructor(private api: ApiService, private authService: AuthMSALService, private errorService: ErrorsService, private msal: MsalService,
    private router: Router, private userService: UserService, private dialog: MatDialog) {
  }

  getJWT() {
    /* this.msal.instance.getTokenCache()['storage'].browserStorage.getKeys().forEach(el => {
      if (el.includes('accesstoken')) {
        this.accessToken = JSON.parse(this.msal.instance.getTokenCache()['storage'].browserStorage.windowStorage.getItem(el));
        this.jwt = this.accessToken.secret;
      }
    }); */
    this.jwt = localStorage.getItem('token')
  }

  getTenantActive() {
    this.tenantId = this.userService.getTenantActive()?.id || this.userService.getTenantActive()?.tenantId;
    // this.userService.tenantActive.subscribe(result => {
    //   console.log('RESULT', result)
    //   if (Object.keys(result).length > 0) {
    //     this.tenantId = result.tenantId;
    //   }
    // })
  }

  addToken(req, token) {
    // Set new token
    this.jwt = localStorage.setItem('token', token)

    return req.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

  }

  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken;
  handleUnauthorized(req: HttpRequest<any>, next: HttpHandler, observer) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      // get a new token via userService.refreshToken
      this.msal.acquireTokenSilent(tokenRequest)
        .subscribe(response => {
          //console.log('RESPONSE', response)

          // did we get a new token retry previous request
          if (response.accessToken) {
            this.tokenSubject.next(response.accessToken);

            return next.handle(this.addToken(req, response.accessToken)).subscribe((event => {
              if (event instanceof HttpResponse) {
                observer.next(event);
              }
            }));

          }

          //console.log('NO TOKEN')
          // If we don't get a new token, we are in trouble so logout.
          //this.userService.doLogout();
          //return observableThrowError('');
        }, error => {

          // If there is an exception calling 'refreshToken', bad news so logout.
          (<any>sessionStorage).setItem('tokenExpired', true);
          this.authService.logout();

          //return observableThrowError('');
        }, () => {
          this.isRefreshingToken = false;
        });
    } else {
      this.tokenSubject
        .pipe(
          filter(token => token != null),
          take(1),
          switchMap(token => {
            this.jwt = token;
            // console.log('SUB', this.jwt)

            return next.handle(this.addToken(req, token)).pipe(tap(event => {
              if (event instanceof HttpResponse) {
                observer.next(event);
              }
            }));
          })
        ).subscribe();
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // let headersOptions = new HttpHeaders();
    /*   let currentAccount = this.msal.instance.getAccountByHomeId('2c4eedaf-c733-4aea-956d-b1f76d08ea54-b2c_1a_signup_signin.bc86cb70-7f20-4441-9f32-98d2244cf684');
      let requestToken = {
        account: currentAccount,
        ...tokenRequest
      } */

    // Company Pages - DIRECTUS URL
    if (req.url.includes('directus')) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer w0zTaLUJ5AtxiX0QwtTf7keYbEL9tBZy`,
        }
      });
    }

    // API URL
    if (req.url.includes(environment.path.api) || req.url.includes(environment.path.api2)) {
      // headersOptions.append('Access-Control-Allow-Origin', '*');

      if (this.msal.instance.getActiveAccount()) {
        this.getJWT();
        // this.jwt = 'test&faketoken';
        this.getTenantActive();


        if ((req.url.includes(environment.path.api) || req.url.includes(environment.path.api2 + '/cache') || req.url.includes(environment.path.api2)) &&
          this.tenantId)  /*|| req.url.includes(environment.path.api2 + '/organisation') && this.tenantId)*/ {

          req = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${this.jwt}`,
              // 'Content-Type': 'application/json',
              [headersRequest.tenant]: this.tenantId
              //'Ocp-Apim-Subscription-Key': this.apimKey
              // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
              //'siteKey': environment.GlobalVars.brand
            }
          });


        }
        else {
          req = req.clone({
            setHeaders: {
              'Authorization': `Bearer ${this.jwt}`,
              // 'Content-Type': 'application/json',
            }
          });
        }

        // console.log('Api Jobology intercepted', req.url);
      }

      return Observable.create(observer => {
        next.handle(req).subscribe
          (event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }
          },
            err => {

              // Handle abonnement errors
              if (err.headers.get('Raison') === 'abo') {
                let popin = this.dialog.open(PopinSubscriptionComponent, {
                  width: '100%',
                  maxWidth: '700px',
                  disableClose: false,
                  autoFocus: false,
                  data: {
                    title: 'Mettez à jour votre abonnement',
                    content: `<p>Pour accéder à cette fonctionnalité, passez à la version supérieure de votre abonnement
                    dès maintenant !</p>`,
                    linkCTA: `${environment.path.blog.mainUrl}/mon-compte/subscriptions/?tenantid=${this.tenantId}`,
                    linkTarget: '_blank',
                    titleCTA: 'Gérer mon abonnement',
                    displayClose: true
                  }
                })
              }

              // Handle popin forbidden access
              if (!err.headers.get('Raison') && err.status === 403) {
                // Create departement CTA click
                if (err.url === `${environment.path.api2}/sourcing/departement`) {
                  let popin = this.dialog.open(PopinForbiddenComponent, {
                    width: '100%',
                    maxWidth: '700px',
                    disableClose: false,
                    autoFocus: false,

                  })
                }
              }

              // Handle errors only for GraphQL API
              if (req.url.includes(environment.path.api)) {
                this.errorService.handleError(err);
              }

              // Handle errors of token authorization
              // if (err.status === 401 || err.status === 400) {
              if (err.status === 401) {
                return this.handleUnauthorized(req, next, observer);
              }

              observer.error(err);

            },
            () => {
              observer.complete();
            }
          )
      });
    }

    // Auth URL
    if (req.url.includes(environment.path.auth)) {
      return Observable.create(observer => {
        next.handle(req).subscribe(
          event => {
            if (event instanceof HttpResponse) {
              observer.next(event);
            }

          },
          err => {

            this.errorService.handleAuthError(err);
            observer.error(err);

          },
          () => {
            observer.complete();
          }
        )
      });
    }

    return next.handle(req);
  }
}